const apiTags = {
  tag: 'tag',
  room: 'room',
  group: 'group',
  menuItemCategory: 'menuItemCategory',
  menuItemVariantSet: 'menuItemVariantSet',
  menuItemVariant: 'menuItemVariant',
  emailNotification: 'emailNotification',
  playlist: 'playlist',
  table: 'table',
  template: 'template',
  templateGallery: 'templateGallery',
  designCategory: 'designCategory',
  user: 'user',
  featureManagement: 'featureManagement',
  eulaTerms: 'eulaTerms',
  mediaLibrary: 'mediaLibrary',
  clientUsers: 'clientUsers',
  emailTemplate: 'emailTemplate',
  category: 'category',
  clients: 'clients',
  workplacePoster: 'workplacePoster',
  workplacePosterTags: 'workplacePosterTags',
  device: 'device',
  devicePackage: 'devicePackage',
  filter: 'filter',
  aiVideoCreator: 'aiVideoCreator',
  mediaCategoryList: 'mediaCategoryList',
  licenses: 'licenses',
  report: 'report',
  clientLicenses: 'clientLicenses',
  supportToken: 'supportToken',
  deviceUpTime: 'deviceUpTime',
  contents: 'contents',
  templateLibrary: 'templateLibrary',
  templatesBackground: 'templatesBackground',
  dashboard: 'dashboard',
  schedule: 'schedule',
  appVersion: 'appVersion'
}

export default apiTags
