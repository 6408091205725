import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Radio, Tooltip, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { Checkbox } from 'components/Checkboxes'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { TextWithTooltip } from 'components/Typography'
import { ActiveStatus, InactiveStatus } from 'components/StatusText'
import ScheduleWorkingDays from 'components/Pages/Schedule/ScheduleWorkingDays'
import { getUrlPrefix } from 'utils'
import {
  entityConstants,
  routeByName,
  scheduleConstants
} from 'constants/index'
import { getSchedulePreview } from 'actions/scheduleActions'
import { libraryViewSelector } from 'selectors/libraryViewSelectors'

const styles = ({ palette, type, spacing }) => ({
  listItem: {
    padding: '8px 0',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`
  },
  spacing: {
    margin: `0 ${spacing(1)}px`
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  faded: {
    opacity: 0.5
  },
  disablePreview: {
    pointerEvents: 'none'
  }
})

const ScheduleListRow = ({
  item,
  onSelect,
  selectType = 'radio',
  selected,
  withLink,
  disabled,
  classes,
  disablePreview
}) => {
  const dispatch = useDispatch()
  const {
    id,
    scheduleType,
    title,
    status,
    workingDays = '',
    orientation
  } = item

  const { t } = useTranslation('translations')

  const handleSelect = useCallback(() => {
    onSelect(item)
  }, [onSelect, item])

  const scheduleTypeInfo = useMemo(
    () =>
      scheduleConstants.scheduleTypes[scheduleType] ||
      scheduleConstants.scheduleTypes.Timed,
    [scheduleType]
  )
  const viewSettings = useSelector(libraryViewSelector)
  const view = viewSettings[entityConstants.ScheduleLibrary]

  const renderTitle = useMemo(() => {
    return withLink ? (
      <Link
        to={getUrlPrefix(
          `${
            view === 'calendar'
              ? routeByName.schedule.calendar
              : routeByName.schedule.list
          }?id=${id}&exact=true`
        )}
        target="_blank"
        className={classNames(classes.link)}
      >
        <TextWithTooltip
          color="title.primary"
          weight="bold"
          maxWidth={120}
          rootClassName={classes.spacing}
        >
          {title}
        </TextWithTooltip>
      </Link>
    ) : (
      <TextWithTooltip
        color="title.primary"
        weight="bold"
        maxWidth={120}
        rootClassName={classes.spacing}
      >
        {title}
      </TextWithTooltip>
    )
  }, [id, title, withLink, classes, view])

  const handlePreviewClick = useCallback(() => {
    dispatch(getSchedulePreview({ id, orientation }))
  }, [dispatch, id, orientation])

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(classes.listItem, {
        [classes.faded]: disabled
      })}
    >
      <Grid container alignItems="center" item xs={5}>
        {selectType === 'checkbox' && (
          <Checkbox
            checked={selected}
            onClick={handleSelect}
            className={classes.spacing}
            disabled={disabled}
          />
        )}
        {selectType === 'radio' && (
          <Radio checked={selected} onClick={handleSelect} />
        )}
        <Tooltip arrow title={scheduleTypeInfo.title}>
          <LibraryTypeIcon
            onClick={handlePreviewClick}
            color={scheduleTypeInfo.color}
            icon={scheduleTypeInfo.icon}
            iconHelperClass={scheduleTypeInfo.iconHelperClass}
            className={classes.spacing}
            wrapHelperClass={classNames({
              [classes.disablePreview]: disablePreview
            })}
          />
        </Tooltip>
        {renderTitle}
      </Grid>
      <Grid
        container
        item
        xs={7}
        justifyContent="space-between"
        alignItems="center"
      >
        <ScheduleWorkingDays workingDays={workingDays.split(',')} />
        {status === 'Active' ? (
          <ActiveStatus title={t('Active')} className={classes.spacing} />
        ) : (
          <InactiveStatus title={t('Inactive')} className={classes.spacing} />
        )}
      </Grid>
    </Grid>
  )
}

ScheduleListRow.propTypes = {
  item: PropTypes.object.isRequired,
  selectType: PropTypes.oneOf(['checkbox', 'radio', 'none']),
  selected: PropTypes.bool,
  withLink: PropTypes.bool,
  onSelect: PropTypes.func
}

export default withStyles(styles)(ScheduleListRow)
