import { createAppApi } from 'services/api'
import { createSuccessInvalidator, getCRUDEndpoints } from '../utils/apiUtils'
import apiTags from '../constants/apiTags'
import { getApiParamsByOriginalUser } from 'utils/localStorage'

export const playlistsApi = createAppApi('playlistsQuery', {
  tagTypes: [apiTags.playlist],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      url: '/playlist',
      name: 'Playlist',
      tags: [apiTags.playlist]
    }),
    getPlaylistsList: builder.query({
      providesTags: [apiTags.playlist],
      query: params => ({
        url: '/playlist/listView',
        params
      })
    }),
    getPlaylistsGrid: builder.query({
      providesTags: [apiTags.playlist],
      query: params => ({
        url: '/playlist/gridView',
        params
      })
    }),
    getPlaylistsGridEmbedded: builder.query({
      providesTags: [apiTags.playlist],
      query: params => ({
        url: '/playlist/gridViewEmbedded',
        params
      })
    }),
    postInteractivePlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.playlist]),
      query: data => ({
        method: 'POST',
        url: '/interactivePlaylist',
        data
      })
    }),
    putInteractivePlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.playlist]),
      query: ({ id, ...data }) => ({
        method: 'PUT',
        url: `/interactivePlaylist/${id}`,
        data
      })
    }),
    postSmartPlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.playlist]),
      query: data => ({
        method: 'POST',
        url: '/smartplaylist',
        data
      })
    }),
    putSmartPlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.playlist]),
      query: ({ id, ...data }) => ({
        method: 'PUT',
        url: `/smartplaylist/${id}`,
        data
      })
    }),
    copyPlaylists: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/playlist/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/playlist/bulkCopy'))
        }
      }
    }),
    sharePlaylists: builder.mutation({
      query: (data = {}) => ({
        url: '/playlist/bulkShare',
        method: 'POST',
        data
      })
    }),
    postFolderPlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.playlist]),
      query: data => ({
        method: 'POST',
        url: '/playlist/folder',
        data
      })
    }),
    putFolderPlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.playlist]),
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `/playlist/folder/${id}`,
        data
      })
    })
  })
})

export const {
  //Playlist
  useLazyGetPlaylistsQuery,
  useLazyGetPlaylistQuery,
  useGetPlaylistQuery,
  usePostPlaylistMutation,
  usePutPlaylistMutation,
  usePostInteractivePlaylistMutation,
  usePutInteractivePlaylistMutation,
  usePostSmartPlaylistMutation,
  usePutSmartPlaylistMutation,
  usePostFolderPlaylistMutation,
  usePutFolderPlaylistMutation,
  useDeletePlaylistMutation,
  useCopyPlaylistsMutation,
  useSharePlaylistsMutation,
  useGetPlaylistsQuery,
  useGetPlaylistsListQuery,
  useLazyGetPlaylistsListQuery,
  useLazyGetPlaylistsGridQuery,
  useLazyGetPlaylistsGridEmbeddedQuery
} = playlistsApi
