import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import * as selectUtils from 'utils/select'
import { defaultTag } from 'constants/chipsColorPalette'
import { prepareTagData } from 'utils/tagUtils'
import { parseNestedError } from 'utils/errorHandler'
import { useLazyGetTagsQuery, usePostTagMutation } from 'api/tagsApi'
import { FormControlAutocompleteQuery } from 'components/Form'
import { transformTagToOptions } from 'utils/transformToOptionsUtils'
import { tagCustomStyles } from 'constants/stylesConstants'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'

const FormControlSelectTag = ({
  useLazyQuery = useLazyGetTagsQuery,
  hasDynamicChipsCreation = true,
  styles = {},
  t,
  error: rawError,
  ...props
}) => {
  const [postTag, postResult] = usePostTagMutation()

  const [createdTag, setCreatedTag] = useState({})

  const { getPermissionByName } = usePermissions()

  const tagPermission = useMemo(
    () => ({
      read: getPermissionByName(
        permissionNames.CLIENT_TAG_SHOW,
        permissionNames.SYSTEM_TAG_SHOW,
        permissionNames.ENTERPRISE_TAG_SHOW
      ),
      create: getPermissionByName(
        permissionNames.CLIENT_TAG_STORE,
        permissionNames.SYSTEM_TAG_STORE,
        permissionNames.ENTERPRISE_TAG_STORE
      )
    }),
    [getPermissionByName]
  )

  useEffect(() => {
    if (postResult.isSuccess) {
      const newTag = selectUtils.tagToChipObj(postResult.data)
      setCreatedTag({ data: newTag })
    } else if (postResult.isError) {
      setCreatedTag({ error: postResult.error })
    }
    // eslint-disable-next-line
  }, [postResult])

  const handleCreateTag = useCallback(
    title => {
      const data = prepareTagData({ ...defaultTag, tag: title })
      postTag(data)
    },
    [postTag]
  )

  const handleClearCreatedTag = useCallback(() => postResult.reset(), [
    postResult
  ])

  const customStyles = useMemo(() => ({ ...tagCustomStyles, ...styles }), [
    styles
  ])

  const isCreationAllowed = useMemo(() => {
    return tagPermission.create && hasDynamicChipsCreation
  }, [tagPermission, hasDynamicChipsCreation])

  const noOptionsMessage = useMemo(() => {
    if (!tagPermission.read) {
      return t('No permissions available')
    } else if (!isCreationAllowed) {
      return value => (value ? `No Options for "${value}"` : t('No Options'))
    } else {
      return t('Press Enter to add new Tag')
    }
  }, [tagPermission, isCreationAllowed, t])

  return (
    <FormControlAutocompleteQuery
      useLazyQuery={useLazyQuery}
      searchField="tag"
      responseParser={transformTagToOptions}
      sort="tag"
      isResettable
      isMulti
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      createdValue={createdTag}
      createNewValue={handleCreateTag}
      clearCreatedValue={handleClearCreatedTag}
      hasDynamicChipsCreation={isCreationAllowed}
      error={parseNestedError(rawError)}
      {...props}
    />
  )
}

FormControlSelectTag.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  styles: PropTypes.object,
  hasDynamicChipsCreation: PropTypes.bool,
  getOptions: PropTypes.func
}

export default withTranslation('translations')(memo(FormControlSelectTag))
