const helpPages = {
  UserLibrary: 'UserLibrary',
  UserAdd: 'UserAdd',
  UserGroup: 'UserGroup',
  ClientLibrary: 'ClientLibrary',
  ClientAdd: 'ClientAdd',
  ClientGroup: 'ClientGroup',
  DeviceLibrary: 'DeviceLibrary',
  DeviceNOC: 'DeviceNOC',
  DeviceGroup: 'DeviceGroup',
  MediaLibrary: 'MediaLibrary',
  MediaAdd: 'MediaAdd',
  MediaGroup: 'MediaGroup',
  PlaylistLibrary: 'PlaylistLibrary',
  PlaylistGroup: 'PlaylistGroup',
  PlaylistAdd: 'PlaylistAdd',
  TemplateLibrary: 'TemplateLibrary',
  TemplateAdd: 'TemplateAdd',
  TemplateGroup: 'TemplateGroup',
  ScheduleLibrary: 'ScheduleLibrary',
  ScheduleAdd: 'ScheduleAdd',
  ScheduleGroup: 'ScheduleGroup',
  FontLibrary: 'FontLibrary',
  UserRole: 'UserRole'
}

export default helpPages
