import React, { cloneElement, memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import Popup from 'components/Popup'
import { CircleIconButton } from 'components/Buttons'
import { FormControlReactSelect } from 'components/Form'
import { scheduleAssignTypes, scheduleTypes } from 'constants/schedule'
import { Text } from 'components/Typography'
import Tooltip from 'components/Tooltip'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'

const styles = ({ colors, spacing }) => ({
  root: {
    height: 52
  },
  warningIcon: {
    color: colors.light,
    marginLeft: spacing(1)
  }
})

const dropdownStyle = {
  borderRadius: 6,
  width: 315,
  animation: 'fade-in 200ms'
}

const ContentSectionHeader = ({
  filterComponent: FilterComponent,
  titleSearchComponent: TitleSearchComponent,
  assignType,
  disableTypeSelection,
  onChange,
  hasSelectedItems = f => f,
  scheduleType,
  classes,
  t
}) => {
  const { getPermissionByName } = usePermissions()

  const tagPermissions = useMemo(
    () => ({
      read: getPermissionByName(
        permissionNames.CLIENT_TAG_SHOW,
        permissionNames.SYSTEM_TAG_SHOW,
        permissionNames.ENTERPRISE_TAG_SHOW
      )
    }),
    [getPermissionByName]
  )

  const deviceGroupPermission = useMemo(
    () => ({
      read: getPermissionByName(permissionNames.SHOW_DEVICE_GROUP_ASSOCIATION)
    }),
    [getPermissionByName]
  )
  const deviceLocationPermission = useMemo(
    () => ({
      read: getPermissionByName(permissionNames.ORG_DEVICE_LOCATION_VIEW_SHOW)
    }),
    [getPermissionByName]
  )

  const handleTypeChange = useCallback(
    e => {
      onChange(e)
    },
    [onChange]
  )

  const hasSelectedItemsByType = useMemo(
    () => ({
      [scheduleAssignTypes.GROUP]: hasSelectedItems(scheduleAssignTypes.GROUP),
      [scheduleAssignTypes.TAG]: hasSelectedItems(scheduleAssignTypes.TAG),
      [scheduleAssignTypes.DEVICE]: hasSelectedItems(
        scheduleAssignTypes.DEVICE
      ),
      [scheduleAssignTypes.LOCATION]: hasSelectedItems(
        scheduleAssignTypes.LOCATION
      )
    }),
    [hasSelectedItems]
  )

  const createTypeOptionLabel = useCallback(
    (text, type) =>
      assignType === type
        ? t(text)
        : `${text}${hasSelectedItemsByType[type] ? '*' : ''}`,
    [hasSelectedItemsByType, t, assignType]
  )

  const contentTypeOptions = useMemo(() => {
    const devicesOption = {
      label: createTypeOptionLabel('Devices', scheduleAssignTypes.DEVICE),
      value: scheduleAssignTypes.DEVICE
    }

    if (scheduleType === scheduleTypes.Failover.name) {
      return [devicesOption]
    }

    return [
      devicesOption,
      ...(tagPermissions.read || hasSelectedItemsByType.tag
        ? [
            {
              label: createTypeOptionLabel('Tags', scheduleAssignTypes.TAG),
              value: scheduleAssignTypes.TAG
            }
          ]
        : []),
      ...(deviceGroupPermission.read || hasSelectedItemsByType.group
        ? [
            {
              label: createTypeOptionLabel('Groups', scheduleAssignTypes.GROUP),
              value: scheduleAssignTypes.GROUP
            }
          ]
        : []),
      ...(deviceLocationPermission.read || hasSelectedItemsByType.location
        ? [
            {
              label: createTypeOptionLabel(
                'Locations',
                scheduleAssignTypes.LOCATION
              ),
              value: scheduleAssignTypes.LOCATION
            }
          ]
        : [])
    ]
  }, [
    deviceGroupPermission,
    tagPermissions,
    deviceLocationPermission,
    createTypeOptionLabel,
    hasSelectedItemsByType,
    scheduleType
  ])

  const renderFilterComponent = useCallback(
    close => {
      return cloneElement(FilterComponent, { close })
    },
    [FilterComponent]
  )

  const sectionHeaderText = useMemo(
    () => contentTypeOptions.find(({ value }) => value === assignType)?.label,
    [contentTypeOptions, assignType]
  )

  const hasWarningIcon = useMemo(
    () =>
      Object.entries(hasSelectedItemsByType)
        .filter(([key]) => key !== assignType)
        .some(([, value]) => value),
    [hasSelectedItemsByType, assignType]
  )

  return (
    <Grid alignItems="center" container spacing={1} className={classes.root}>
      <Grid item xs={hasWarningIcon ? 5 : 4}>
        {disableTypeSelection ? (
          <Text weight="bold" color="title.primary">
            {sectionHeaderText}
          </Text>
        ) : (
          <Grid container wrap="nowrap" alignItems="center">
            <FormControlReactSelect
              name="assignType"
              value={assignType}
              onChange={handleTypeChange}
              options={contentTypeOptions}
              marginBottom={false}
            />
            {hasWarningIcon && (
              <Tooltip
                arrow
                placement="top"
                title={t('Other tab(s) contain selected items')}
              >
                <i
                  className={classNames(
                    classes.warningIcon,
                    'fa-sharp fa-regular fa-circle-exclamation'
                  )}
                />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
      <Grid xs={hasWarningIcon ? 7 : 8} item alignItems="center" container>
        <Grid item xs={FilterComponent ? 10 : 12}>
          {TitleSearchComponent && TitleSearchComponent}
        </Grid>
        {FilterComponent && (
          <Grid item xs={2}>
            <Popup
              on="click"
              position="bottom right"
              contentStyle={dropdownStyle}
              trigger={
                <CircleIconButton className="hvr-grow">
                  <i className="fa-regular fa-sliders" />
                </CircleIconButton>
              }
            >
              {renderFilterComponent}
            </Popup>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

ContentSectionHeader.propTypes = {
  filterComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object
  ]),
  titleSearchComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object
  ]).isRequired,
  assignType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disableTypeSelection: PropTypes.bool,
  hasSelectedItems: PropTypes.func
}

export default withTranslation('translations')(
  withStyles(styles)(memo(ContentSectionHeader))
)
