import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'

export const reportsApi = createAppApi('reportsQuery', {
  tagTypes: [apiTags.report],
  endpoints: builder => ({
    //TODO rename getReports and getMultipleReport to match the url
    getReports: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/countApiCall`,
        params: { ...params }
      })
    }),
    getMultipleReport: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: '/report',
        params
      })
    })
  })
})

export const { useLazyGetReportsQuery, useGetMultipleReportQuery } = reportsApi
