export const headDropdownOffset = 340
export const headDropdownColumnItemHeight = 38
export const defaultMaxHeadDropdownColumnItems = 14

export const columnTypes = {
  text: 'text',
  dateTime: 'dateTime',
  date: 'date',
  client: 'client',
  tags: 'tags',
  groups: 'groups'
}
