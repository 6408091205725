export const SYSTEM_MEDIA_COPY = 'system media copy'
export const SYSTEM_PLAYLIST_COPY = 'system playlist copy'
export const SYSTEM_TEMPLATE_COPY = 'system template copy'
export const SYSTEM_SCHEDULE_COPY = 'system schedule copy'
export const ENTERPRISE_MEDIA_COPY = 'enterprise media copy'
export const ENTERPRISE_PLAYLIST_COPY = 'enterprise playlist copy'
export const ENTERPRISE_TEMPLATE_COPY = 'enterprise template copy'
export const CLIENT_MEDIA_APPROVAL = 'client media approval'
export const CLIENT_PLAYLIST_APPROVAL = 'client playlist approval'
export const CLIENT_TEMPLATE_APPROVAL = 'client template approval'
export const CLIENT_SCHEDULE_APPROVAL = 'client schedule approval'
export const SYSTEM_CLIENT_IMPERSONATE = 'system impersonate client users'
export const ENTERPRISE_CLIENT_IMPERSONATE =
  'enterprise impersonate client users'
export const RESELLER_CLIENT_IMPERSONATE = 'reseller client impersonate'
export const RESELLER_CLIENT_USER_READ = 'reseller associated client user show'
export const CLIENT_MEDIA_STORE = 'client media store'
export const ORG_USER_PROFILE_IMAGE_READ = 'org image profile image show'
export const SYSTEM_USER_PROFILE_IMAGE_READ = 'system image profile image show'
export const ENTERPRISE_USER_PROFILE_IMAGE_READ =
  'enterprise image profile image show'
export const SYSTEM_DEVICE_ERROR_LOG = 'system get device error log'
export const ORG_DEVICE_ERROR_LOG = 'org get device error log'
export const SYSTEM_CLIENT_BLOCKING = 'system client blocking'
export const ORG_MEDIA_SHORELINE_PRICE_SHOW = 'org media shoreline price show'
export const ORG_MEDIA_SHORELINE_PRICE_STORE = 'org media shoreline price store'
export const ORG_MEDIA_SHORELINE_EVENT_SHOW =
  'org media shoreline event type show'
export const ORG_MEDIA_SHORELINE_EVENT_STORE =
  'org media shoreline event type store'
export const CLIENT_DESIGN_GALLERY_SHOW = 'client design gallery show'
export const CLIENT_DESIGN_GALLERY_STORE = 'client design gallery store'
export const CLIENT_DESIGN_GALLERY_UPDATE = 'client design gallery update'
export const CLIENT_DESIGN_GALLERY_DELETE = 'client design gallery delete'
export const ORG_USER_DEVICE_HEALTH_READ = 'org show device health'
export const SYSTEM_USER_DEVICE_HEALTH_READ = 'system show device health'
export const SYSTEM_RUN_REPORT = 'system run report'
export const ORG_RUN_REPORT = 'org run report'
export const SYSTEM_DESIGN_GALLERY_SHARE = 'system design gallery share'
export const SYSTEM_BANNER_CLONE = 'system banner clone'
export const SYSTEM_TRIGGER_DEVICE_HEALTH = 'system trigger device health'
export const SYSTEM_DEVICE_THUMBNAIL_TRIGGER = 'system device thumbnail trigger'
export const SYSTEM_MEDIA_SHARE = 'system media share'
export const SYSTEM_PLAYLIST_SHARE = 'system playlist share'
export const SYSTEM_TEMPLATE_SHARE = 'system template share'
export const ENTERPRISE_DESIGN_GALLERY_SHARE = 'enterprise design gallery share'
export const ENTERPRISE_BANNER_CLONE = 'enterprise banner clone'
export const ENTERPRISE_TRIGGER_DEVICE_HEALTH =
  'enterprise trigger device health'
export const ENTERPRISE_DEVICE_THUMBNAIL_TRIGGER =
  'enterprise device thumbnail trigger'
export const ENTERPRISE_MEDIA_SHARE = 'enterprise media share'
export const ENTERPRISE_PLAYLIST_SHARE = 'enterprise playlist share'
export const ENTERPRISE_TEMPLATE_SHARE = 'enterprise template share'
export const ENTERPRISE_SCHEDULE_COPY = 'enterprise schedule copy'
export const ORG_DEVICE_NOC = 'org device noc'
export const SYSTEM_SHOW_REPORTS_CONFIG = 'system show reports config'
export const SYSTEM_SHOW_REPORT_TABLE_CONFIG = 'system show report table config'
export const ORG_SHOW_REPORTS_CONFIG = 'org show reports config'
export const ORG_SHOW_REPORT_TABLE_CONFIG = 'org show report table config'
export const SYSTEM_USER_LIBRARY_RECORD_EXPORT =
  'system user library record export'
export const SYSTEM_CLIENT_LIBRARY_RECORD_EXPORT =
  'system client library record export'
export const SYSTEM_CLIENT_USER_LIBRARY_RECORD_EXPORT =
  'system clientUser library record export'
export const SYSTEM_DEVICE_LIBRARY_RECORD_EXPORT =
  'system device library record export'
export const SYSTEM_MEDIA_LIBRARY_RECORD_EXPORT =
  'system media library record export'
export const SYSTEM_PLAYLIST_LIBRARY_RECORD_EXPORT =
  'system playlist library record export'
export const SYSTEM_TEMPLATE_LIBRARY_RECORD_EXPORT =
  'system template library record export'
export const SYSTEM_SCHEDULE_LIBRARY_RECORD_EXPORT =
  'system schedule library record export'
export const CLIENT_DEVICE_STORE = 'client device store'
export const SYSTEM_DEVICE_STORE = 'system device store'

export const IP_PROVIDER_SHOW = 'ip provider show'
export const IP_PROVIDER_UPDATE = 'ip provider update'

export const CLIENT_DEVICE_SHOW = 'client device show'
export const SYSTEM_DEVICE_SHOW = 'system device show'
export const ENTERPRISE_DEVICE_SHOW = 'enterprise device show'

export const CLIENT_DEVICE_UPDATE = 'client device update'
export const SYSTEM_DEVICE_UPDATE = 'system device update'
export const ENTERPRISE_DEVICE_UPDATE = 'enterprise device update'

export const SYSTEM_DEVICE_DESTROY = 'system device destroy'

export const CLIENT_DEVICE_ALERT_SHOW = 'client device alert show'
export const CLIENT_DEVICE_ALERT_MANAGE = 'client device alert manage'

export const SHOW_DEVICE_GROUP_ASSOCIATION = 'show device group association'
export const CREATE_DEVICE_GROUP_ASSOCIATION = 'create device group association'
export const DELETE_DEVICE_GROUP_ASSOCIATION = 'delete device group association'

export const DEVICE_OFFLINE_NOTIFICATION_SHOW =
  'device offline notification show'
export const DEVICE_OFFLINE_NOTIFICATION_STORE =
  'device offline notification store'
export const DEVICE_OFFLINE_NOTIFICATION_UPDATE =
  'device offline notification update'
export const DEVICE_OFFLINE_NOTIFICATION_DELETE =
  'device offline notification delete'

export const ORG_DEVICE_LOCATION_VIEW_SHOW = 'org device location view show'
export const ORG_DEVICE_LOCATION_VIEW_CREATE = 'org device location view create'
export const ORG_DEVICE_LOCATION_VIEW_UPDATE = 'org device location view update'
export const ORG_DEVICE_LOCATION_VIEW_DELETE = 'org device location view delete'

export const ORG_SHOW_DEVICE_HEALTH = 'org show device health'
export const SYSTEM_SHOW_DEVICE_HEALTH = 'system show device health'
export const ENTERPRISE_SHOW_DEVICE_HEALTH = 'enterprise show device health'

export const CLIENT_DEVICE_REBOOT_SHOW = 'client device reboot show'
export const SYSTEM_DEVICE_REBOOT_SHOW = 'system device reboot show'
export const ENTERPRISE_DEVICE_REBOOT_SHOW = 'enterprise device reboot show'

export const CLIENT_DEVICE_REBOOT_UPDATE = 'client device reboot update'
export const SYSTEM_DEVICE_REBOOT_UPDATE = 'system device reboot update'
export const ENTERPRISE_DEVICE_REBOOT_UPDATE = 'enterprise device reboot update'

export const SYSTEM_DEVICE_TYPE_ASSIGN_APP_VERSION =
  'system device type assign app version'

export const CLIENT_DEVICE_APPLY_APP_VERSION = 'client device apply app version'
export const SYSTEM_DEVICE_ASSIGN_APP_VERSION =
  'system device assign app version'
export const ENTERPRISE_DEVICE_ASSIGN_APP_VERSION =
  'enterprise device apply app version'

export const ORG_DEVICE_THUMBNAIL_TRIGGER = 'org device thumbnail trigger'

export const ORG_DEVICE_LOCATION_IMAGE_SHOW = 'org device location image show'
export const SYSTEM_DEVICE_LOCATION_IMAGE_SHOW =
  'system device location image show'
export const ENTERPRISE_DEVICE_LOCATION_IMAGE_SHOW =
  'enterprise device location image show'

export const SYSTEM_DEVICE_REFRESH_LICENSE_STORE =
  'system device refresh license store'
export const SYSTEM_DEVICE_REFRESH_LICENSE_UPDATE =
  'system device refresh license update'
export const SYSTEM_DEVICE_REFRESH_LICENSE_DESTROY =
  'system device refresh license destroy'

export const SYSTEM_DEVICE_LICENSE_SHOW = 'system device license show'
export const SYSTEM_DEVICE_LICENSE_UPDATE = 'system device license update'
export const SYSTEM_DEVICE_LICENSE_DELETE = 'system device license delete'

export const SYSTEM_DEVICE_TYPE_STORE = 'system device type store'
export const SYSTEM_DEVICE_TYPE_UPDATE = 'system device type update'

export const DEVICE_ALARM_NOTIFICATION_SHOW = 'device alarm notification show'
export const DEVICE_ALARM_NOTIFICATION_STORE = 'device alarm notification store'
export const DEVICE_ALARM_NOTIFICATION_UPDATE =
  'device alarm notification update'
export const DEVICE_ALARM_NOTIFICATION_DELETE =
  'device alarm notification delete'

export const CLIENT_DASHBOARD_ACCESS = 'client dashboard access'
export const SYSTEM_DASHBOARD_ACCESS = 'system dashboard access'
export const ENTERPRISE_DASHBOARD_ACCESS = 'enterprise dashboard access'

export const CLIENT_SCHEDULE_SHOW = 'client schedule show'
export const CLIENT_SCHEDULE_STORE = 'client schedule store'
export const CLIENT_SCHEDULE_UPDATE = 'client schedule update'
export const CLIENT_SCHEDULE_DELETE = 'client schedule delete'

export const CLIENT_PLAYLIST_SHOW = 'client playlist show'
export const CLIENT_PLAYLIST_STORE = 'client playlist store'
export const CLIENT_PLAYLIST_UPDATE = 'client playlist update'
export const CLIENT_PLAYLIST_DELETE = 'client playlist delete'

export const SYSTEM_PLAYLIST_SHOW = 'system playlist show'
export const SYSTEM_PLAYLIST_STORE = 'system playlist store'
export const SYSTEM_PLAYLIST_UPDATE = 'system playlist update'
export const SYSTEM_PLAYLIST_DELETE = 'system playlist delete'

export const ORG_DEVICE_SCHEDULE_UPDATE = 'org device schedule update'
export const SYSTEM_DEVICE_SCHEDULE_UPDATE = 'system device schedule update'
export const ENTERPRISE_DEVICE_SCHEDULE_UPDATE =
  'enterprise device schedule update'

export const ENTERPRISE_PLAYLIST_SHOW = 'enterprise playlist show'
export const ENTERPRISE_PLAYLIST_STORE = 'enterprise playlist store'
export const ENTERPRISE_PLAYLIST_UPDATE = 'enterprise playlist update'
export const ENTERPRISE_PLAYLIST_DELETE = 'enterprise playlist delete'

export const SYSTEM_MEDIA_CONTENT_SHOW = 'system media content show'
export const SYSTEM_MEDIA_CONTENT_STORE = 'system media content store'
export const SYSTEM_MEDIA_CONTENT_UPDATE = 'system media content update'
export const SYSTEM_MEDIA_CONTENT_DESTROY = 'system media content destroy'

export const SYSTEM_MEDIA_CONTENT_CATEGORY_SHOW =
  'system media content category show'
export const SYSTEM_MEDIA_CONTENT_CATEGORY_STORE =
  'system media content category store'
export const SYSTEM_MEDIA_CONTENT_CATEGORY_UPDATE =
  'system media content category update'
export const SYSTEM_MEDIA_CONTENT_CATEGORY_DESTROY =
  'system media content category destroy'

export const SYSTEM_TEMPLATE_BACKGROUND_PATTERN_STORE =
  'system template background pattern store'
export const SYSTEM_TEMPLATE_BACKGROUND_PATTERN_UPDATE =
  'system template background pattern update'

export const CLIENT_TEMPLATE_SHOW = 'client template show'
export const CLIENT_TEMPLATE_STORE = 'client template store'
export const CLIENT_TEMPLATE_UPDATE = 'client template update'
export const CLIENT_TEMPLATE_DELETE = 'client template delete'

export const SYSTEM_TEMPLATE_SHOW = 'system template show'
export const SYSTEM_TEMPLATE_STORE = 'system template store'
export const SYSTEM_TEMPLATE_UPDATE = 'system template update'
export const SYSTEM_TEMPLATE_DELETE = 'system template delete'

export const ENTERPRISE_TEMPLATE_SHOW = 'enterprise template show'
export const ENTERPRISE_TEMPLATE_STORE = 'enterprise template store'
export const ENTERPRISE_TEMPLATE_UPDATE = 'enterprise template update'
export const ENTERPRISE_TEMPLATE_DELETE = 'enterprise template delete'

export const CLIENT_TEMPLATE_GROUP_ASSOCIATION_SHOW =
  'client template group association show'
export const CLIENT_TEMPLATE_GROUP_ASSOCIATION_STORE =
  'client template group association store'
export const CLIENT_TEMPLATE_GROUP_ASSOCIATION_DELETE =
  'client template group association delete'

export const CLIENT_SCHEDULE_GROUP_ASSOCIATION_SHOW =
  'client schedule group association show'
export const CLIENT_SCHEDULE_GROUP_ASSOCIATION_STORE =
  'client schedule group association store'
export const CLIENT_SCHEDULE_GROUP_ASSOCIATION_DELETE =
  'client schedule group association delete'

export const ENTERPRISE_MEDIA_SHOW = 'enterprise media show'
export const ENTERPRISE_MEDIA_STORE = 'enterprise media store'
export const ENTERPRISE_MEDIA_UPDATE = 'enterprise media update'
export const ENTERPRISE_MEDIA_DELETE = 'enterprise media delete'

export const CLIENT_MEDIA_SHOW = 'client media show'
export const CLIENT_MEDIA_UPDATE = 'client media update'
export const CLIENT_MEDIA_DELETE = 'client media delete'

export const SYSTEM_MEDIA_SHOW = 'system media show'
export const SYSTEM_MEDIA_STORE = 'system media store'
export const SYSTEM_MEDIA_UPDATE = 'system media update'
export const SYSTEM_MEDIA_DELETE = 'system media delete'

export const CLIENT_INTERACTIVE_PLAYLIST_STORE =
  'client interactive playlist store'
export const CLIENT_INTERACTIVE_PLAYLIST_UPDATE =
  'client interactive playlist update'

export const CLIENT_PLAYLIST_GROUP_ASSOCIATION_SHOW =
  'client playlist group association show'
export const CLIENT_PLAYLIST_GROUP_ASSOCIATION_STORE =
  'client playlist group association store'
export const CLIENT_PLAYLIST_GROUP_ASSOCIATION_DELETE =
  'client playlist group association delete'

export const CLIENT_SMART_PLAYLIST_STORE = 'client smartplaylist store'
export const CLIENT_SMART_PLAYLIST_UPDATE = 'client smartplaylist update'

export const CLIENT_ONE_DRIVE_PLAYLIST_STORE = 'client one drive playlist store'
export const CLIENT_ONE_DRIVE_PLAYLIST_UPDATE =
  'client one drive playlist update'

export const CLIENT_MEDIA_GROUP_ASSOCIATION_SHOW =
  'client media group association show'
export const CLIENT_MEDIA_GROUP_ASSOCIATION_STORE =
  'client media group association store'
export const CLIENT_MEDIA_GROUP_ASSOCIATION_DELETE =
  'client media group association delete'

export const ENTERPRISE_MEDIA_GROUP_SHOW = 'enterprise media group show'
export const ENTERPRISE_MEDIA_GROUP_STORE = 'enterprise media group store'
export const ENTERPRISE_MEDIA_GROUP_DESTROY = 'enterprise media group destroy'

export const CLIENT_MEDIA_FONT_SHOW = 'client media font show'
export const CLIENT_MEDIA_FONT_STORE = 'client media font store'
export const CLIENT_MEDIA_FONT_DELETE = 'client media font delete'

export const SYSTEM_MEDIA_FONT_SHOW = 'system media font show'
export const SYSTEM_MEDIA_FONT_STORE = 'system media font store'
export const SYSTEM_MEDIA_FONT_DESTROY = 'system media font destroy'

export const ENTERPRISE_FONT_SHOW = 'enterprise font show'
export const ENTERPRISE_FONT_STORE = 'enterprise font store'
export const ENTERPRISE_FONT_DELETE = 'enterprise font delete'

export const SYSTEM_DESIGN_GALLERY_SHOW = 'system design gallery show'
export const SYSTEM_DESIGN_GALLERY_STORE = 'system design gallery store'
export const SYSTEM_DESIGN_GALLERY_UPDATE = 'system design gallery update'
export const SYSTEM_DESIGN_GALLERY_DELETE = 'system design gallery delete'

export const ENTERPRISE_DESIGN_GALLERY_SHOW = 'enterprise design gallery show'
export const ENTERPRISE_DESIGN_GALLERY_STORE = 'enterprise design gallery store'
export const ENTERPRISE_DESIGN_GALLERY_UPDATE =
  'enterprise design gallery update'
export const ENTERPRISE_DESIGN_GALLERY_DELETE =
  'enterprise design gallery delete'

export const CLIENT_SETTINGS_SHOW = 'client settings show'
export const CLIENT_SETTINGS_UPDATE = 'client settings update'

export const SYSTEM_SETTING_SHOW = 'system setting show'
export const SYSTEM_SETTING_UPDATE = 'system setting update'

export const ENTERPRISE_SETTING_SHOW = 'enterprise setting show'
export const ENTERPRISE_SETTING_UPDATE = 'enterprise setting update'

export const CLIENT_GROUP_SHOW = 'client group show'
export const CLIENT_GROUP_UPDATE = 'client group update'
export const CLIENT_GROUP_STORE = 'client group STORE'
export const CLIENT_GROUP_DELETE = 'client group delete'

export const SYSTEM_GROUP_SHOW = 'system group show'
export const SYSTEM_GROUP_STORE = 'system group store'
export const SYSTEM_GROUP_UPDATE = 'system group update'
export const SYSTEM_GROUP_DESTROY = 'system group destroy'

export const ENTERPRISE_GROUP_SHOW = 'enterprise group show'
export const ENTERPRISE_GROUP_STORE = 'enterprise group store'
export const ENTERPRISE_GROUP_UPDATE = 'enterprise group update'
export const ENTERPRISE_GROUP_DESTROY = 'enterprise group destroy'

export const ENTERPRISE_CLIENT_GROUP_SHOW = 'enterprise client group show'
export const ENTERPRISE_CLIENT_GROUP_CREATE = 'enterprise client group create'
export const ENTERPRISE_CLIENT_GROUP_DESTROY = 'enterprise client group destroy'

export const CLIENT_PREFERENCE_SHOW = 'client preference show'
export const CLIENT_PREFERENCE_STORE = 'client preference store'

export const ENTERPRISE_PREFERENCE_SHOW = 'enterprise preference show'
export const ENTERPRISE_PREFERENCE_STORE = 'enterprise preference store'

export const CLIENT_TAG_SHOW = 'client tag show'
export const CLIENT_TAG_STORE = 'client tag store'
export const CLIENT_TAG_UPDATE = 'client tag update'
export const CLIENT_TAG_DELETE = 'client tag delete'

export const SYSTEM_TAG_SHOW = 'system tag show'
export const SYSTEM_TAG_STORE = 'system tag store'
export const SYSTEM_TAG_UPDATE = 'system tag update'
export const SYSTEM_TAG_DESTROY = 'system tag destroy'

export const ENTERPRISE_TAG_SHOW = 'enterprise tag show'
export const ENTERPRISE_TAG_STORE = 'enterprise tag store'
export const ENTERPRISE_TAG_UPDATE = 'enterprise tag update'
export const ENTERPRISE_TAG_DESTROY = 'enterprise tag destroy'

export const ORG_STORE_WITHOUT_GROUP = 'org store without group'
export const ENTERPRISE_STORE_WITHOUT_GROUP = 'enterprise store without group'

export const CLIENT_CALENDAR_AGGREGATOR_SHOW = 'client calendar aggregator show'
export const CLIENT_CALENDAR_AGGREGATOR_STORE =
  'client calendar aggregator store'
export const CLIENT_CALENDAR_AGGREGATOR_UPDATE =
  'client calendar aggregator update'
export const CLIENT_CALENDAR_AGGREGATOR_DELETE =
  'client calendar aggregator delete'

export const SYSTEM_CALENDAR_AGGREGATOR_SHOW = 'system calendar aggregator show'
export const SYSTEM_CALENDAR_AGGREGATOR_STORE =
  'system calendar aggregator store'
export const SYSTEM_CALENDAR_AGGREGATOR_UPDATE =
  'system calendar aggregator update'
export const SYSTEM_CALENDAR_AGGREGATOR_DELETE =
  'system calendar aggregator delete'

export const ENTERPRISE_CALENDAR_AGGREGATOR_SHOW =
  'enterprise calendar aggregator show'
export const ENTERPRISE_CALENDAR_AGGREGATOR_STORE =
  'enterprise calendar aggregator store'
export const ENTERPRISE_CALENDAR_AGGREGATOR_UPDATE =
  'enterprise calendar aggregator update'
export const ENTERPRISE_CALENDAR_AGGREGATOR_DELETE =
  'enterprise calendar aggregator delete'

export const SYSTEM_APP_VERSION_STORE = 'system app version store'
export const SYSTEM_APP_VERSION_UPDATE = 'system app version update'
export const SYSTEM_APP_VERSION_DELETE = 'system app version delete'

export const SYSTEM_CLIENT_LICENSE_SHOW = 'system client license show'
export const SYSTEM_CLIENT_LICENSE_STORE = 'system client license store'
export const SYSTEM_CLIENT_LICENSE_UPDATE = 'system client license update'
export const SYSTEM_CLIENT_LICENSE_DESTROY = 'system client license destroy'

export const SYSTEM_IMPERSONATE_CLIENT_USERS = 'system impersonate client users'

export const SYSTEM_EULA_VERSION_SHOW = 'system EULA version show'
export const SYSTEM_EULA_VERSION_STORE = 'system EULA version store'
export const SYSTEM_EULA_VERSION_UPDATE = 'system EULA version update'
export const SYSTEM_EULA_VERSION_DELETE = 'system EULA version delete'

export const SYSTEM_CLIENT_USER_SHOW = 'system client user show'
export const SYSTEM_CLIENT_USER_STORE = 'system client user store'
export const SYSTEM_CLIENT_USER_UPDATE = 'system client user update'
export const SYSTEM_CLIENT_USER_DESTROY = 'system client user destroy'

export const SYSTEM_CLIENT_SHOW = 'system client show'
export const SYSTEM_CLIENT_STORE = 'system client store'
export const SYSTEM_CLIENT_UPDATE = 'system client update'

export const ENTERPRISE_CLIENT_SHOW = 'enterprise client show'
export const ENTERPRISE_CLIENT_CREATE = 'enterprise client create'
export const ENTERPRISE_CLIENT_UPDATE = 'enterprise client update'

export const SYSTEM_CLIENT_GROUP_SHOW = 'system client group show'
export const SYSTEM_CLIENT_GROUP_STORE = 'system client group store'
export const SYSTEM_CLIENT_GROUP_DESTROY = 'system client group destroy'

export const SYSTEM_CLIENT_NOTE_SHOW = 'system client note show'
export const SYSTEM_CLIENT_NOTE_STORE = 'system client note store'

export const SYSTEM_CONTENT_APP_HELP_PAGE_SHOW =
  'system content app help page show'
export const SYSTEM_CONTENT_APP_HELP_PAGE_UPDATE =
  'system content app help page update'

export const CLIENT_IP_CIDR_SHOW = 'client ip cidr show'
export const CLIENT_IP_CIDR_STORE = 'client ip cidr store'
export const CLIENT_IP_CIDR_UPDATE = 'client ip cidr update'
export const CLIENT_IP_CIDR_DELETE = 'client ip cidr delete'

export const SYSTEM_DYNAMIC_EMAIL_NOTIFICATION_SHOW =
  'system dynamic email notification show'
export const SYSTEM_DYNAMIC_EMAIL_NOTIFICATION_STORE =
  'system dynamic email notification store'
export const SYSTEM_DYNAMIC_EMAIL_NOTIFICATION_UPDATE =
  'system dynamic email notification update'
export const SYSTEM_DYNAMIC_EMAIL_NOTIFICATION_DESTROY =
  'system dynamic email notification destroy'

export const SYSTEM_FEATURE_SHOW = 'system feature show'
export const SYSTEM_FEATURE_UPDATE = 'system feature update'

export const SYSTEM_HELP_PAGE_SHOW = 'system help page show'
export const SYSTEM_HELP_PAGE_UPDATE = 'system help page update'

export const SYSTEM_CLIENT_PACKAGE_SHOW = 'system client package show'
export const SYSTEM_CLIENT_PACKAGE_STORE = 'system client package store'
export const SYSTEM_CLIENT_PACKAGE_UPDATE = 'system client package update'
export const SYSTEM_CLIENT_PACKAGE_DESTROY = 'system client package destroy'

export const SYSTEM_DEVICE_PACKAGE_SHOW = 'system device package show'
export const SYSTEM_DEVICE_PACKAGE_STORE = 'system device package store'
export const SYSTEM_DEVICE_PACKAGE_UPDATE = 'system device package update'
export const SYSTEM_DEVICE_PACKAGE_DESTROY = 'system device package destroy'

export const SYSTEM_BANDWIDTH_PACKAGE_SHOW = 'system bandwidth package show'
export const SYSTEM_BANDWIDTH_PACKAGE_STORE = 'system bandwidth package store'
export const SYSTEM_BANDWIDTH_PACKAGE_UPDATE = 'system bandwidth package update'
export const SYSTEM_BANDWIDTH_PACKAGE_DESTROY =
  'system bandwidth package destroy'

export const CLIENT_RESELLER_USER_SHOW = 'client reseller user show'
export const CLIENT_RESELLER_USER_STORE = 'client reseller user store'
export const CLIENT_RESELLER_USER_UPDATE = 'client reseller user update'
export const CLIENT_RESELLER_USER_DELETE = 'client reseller user delete'

export const CLIENT_USER_SHOW = 'client user show'
export const CLIENT_USER_STORE = 'client user store'
export const CLIENT_USER_UPDATE = 'client user update'
export const CLIENT_USER_DELETE = 'client user delete'

export const SYSTEM_USER_SHOW = 'system user show'
export const SYSTEM_USER_STORE = 'system user store'
export const SYSTEM_USER_UPDATE = 'system user update'

export const ENTERPRISE_USER_SHOW = 'enterprise user show'
export const ENTERPRISE_USER_STORE = 'enterprise user store'
export const ENTERPRISE_USER_UPDATE = 'enterprise user update'
export const ENTERPRISE_USER_DELETE = 'enterprise user delete'

export const CLIENT_USER_PERMISSION_SHOW = 'client user permission show'
export const CLIENT_USER_PERMISSION_UPDATE = 'client user permission update'

export const CLIENT_USER_GROUP_ASSOCIATION_SHOW =
  'client user group association show'
export const CLIENT_USER_GROUP_ASSOCIATION_STORE =
  'client user group association store'
export const CLIENT_USER_GROUP_ASSOCIATION_DELETE =
  'client user group association delete'

export const SYSTEM_HTML_CONTENT_SHOW = 'system html content show'
export const SYSTEM_HTML_CONTENT_STORE = 'system html content store'
export const SYSTEM_HTML_CONTENT_UPDATE = 'system html content update'
export const SYSTEM_HTML_CONTENT_DESTROY = 'system html content destroy'

export const CLIENT_MENU_SHOW = 'client menu show'
export const CLIENT_MENU_STORE = 'client menu store'
export const CLIENT_MENU_UPDATE = 'client menu update'
export const CLIENT_MENU_DELETE = 'client menu delete'

export const SYSTEM_CLIENT_MENU_SHOW = 'system client menu show'
export const SYSTEM_CLIENT_MENU_STORE = 'system client menu store'
export const SYSTEM_CLIENT_MENU_UPDATE = 'system client menu update'
export const SYSTEM_CLIENT_MENU_DELETE = 'system client menu delete'

export const ENTERPRISE_CLIENT_MENU_COMPONENT_SHOW =
  'enterprise client menu component show'
export const ENTERPRISE_CLIENT_MENU_COMPONENT_STORE =
  'enterprise client menu component store'
export const ENTERPRISE_CLIENT_MENU_COMPONENT_UPDATE =
  'enterprise client menu component update'
export const ENTERPRISE_CLIENT_MENU_COMPONENT_DELETE =
  'enterprise client menu component delete'
export const ENTERPRISE_CLIENT_MENU_COMPONENT_SHARE =
  'enterprise client menu component share'

export const ENTERPRISE_CLIENT_MENU_PRESET_SHOW =
  'enterprise client menu preset show'
export const ENTERPRISE_CLIENT_MENU_PRESET_STORE =
  'enterprise client menu preset store'
export const ENTERPRISE_CLIENT_MENU_PRESET_UPDATE =
  'enterprise client menu preset update'
export const ENTERPRISE_CLIENT_MENU_PRESET_DELETE =
  'enterprise client menu preset delete'
export const ENTERPRISE_CLIENT_MENU_PRESET_SHARE =
  'enterprise client menu preset share'

export const SYSTEM_POSTER_TAG_SHOW = 'system poster tag show'
export const SYSTEM_POSTER_TAG_STORE = 'system poster tag store'
export const SYSTEM_POSTER_TAG_DESTROY = 'system poster tag destroy'

export const SYSTEM_POSTER_SHOW = 'system poster show'
export const SYSTEM_POSTER_STORE = 'system poster store'
export const SYSTEM_POSTER_UPDATE = 'system poster update'
export const SYSTEM_POSTER_DESTROY = 'system poster destroy'

export const SYSTEM_ROLE_SHOW = 'system role show'
export const SYSTEM_ROLE_STORE = 'system role store'
export const SYSTEM_ROLE_UPDATE = 'system role update'
