import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from '../constants/apiTags'

export const licensesApi = createAppApi('licenses', {
  tagTypes: [apiTags.licenses],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      tags: [apiTags.licenses],
      name: 'DeviceRefreshLicense',
      url: '/deviceRefreshLicense'
    }),
    assignDeviceRefreshLicense: builder.mutation({
      query: data => ({
        method: 'PUT',
        url: `/deviceRefreshLicense/assign`,
        data
      })
    }),
    getDeviceLicense: builder.query({
      query: (params = {}) => ({
        url: `/deviceLicense`,
        params
      })
    }),
    deleteDeviceRefreshLicenseBulk: builder.mutation({
      query: data => ({
        method: 'DELETE',
        url: `/deviceRefreshLicense/bulk`,
        data
      })
    })
  })
})

export const {
  useLazyGetDeviceRefreshLicensesQuery,
  useDeleteDeviceRefreshLicenseMutation,
  useAssignDeviceRefreshLicenseMutation,
  useLazyGetDeviceLicenseQuery,
  usePutDeviceRefreshLicenseMutation,
  useDeleteDeviceRefreshLicenseBulkMutation,
  useGetDeviceRefreshLicensesQuery
} = licensesApi
