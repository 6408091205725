import apiTags from 'constants/apiTags'
import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'

export const clientLicensesApi = createAppApi('clientLicenses', {
  tagTypes: [apiTags.clientLicenses],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'ClientLicense',
      url: `/clientLicense`,
      builder,
      tags: [apiTags.clientLicenses]
    })
  })
})

export const {
  useLazyGetClientLicensesQuery,
  useLazyGetClientLicenseQuery,
  usePostClientLicenseMutation,
  usePutClientLicenseMutation,
  useDeleteClientLicenseMutation,
  useGetClientLicensesQuery
} = clientLicensesApi
