import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'

export const clientsApi = createAppApi('clientsQuery', {
  tagTypes: [apiTags.clients],
  endpoints: builder => ({
    getDeviceCount: builder.query({
      query: ({ clientId }) => ({ url: `/client/${clientId}/deviceCount` })
    }),
    getClients: builder.query({
      providesTags: [apiTags.clients],
      query: (params = {}) => ({ url: `/client`, params })
    })
  })
})

export const { useLazyGetDeviceCountQuery, useGetClientsQuery } = clientsApi
