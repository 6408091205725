import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Radio, Typography, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import Checkbox from 'components/Checkboxes/Checkbox'
import Tooltip from 'components/Tooltip'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { DateTimeView } from 'components/TableLibrary'
import { TextWithTooltip } from 'components/Typography'

import { playlistConstants, routeByName } from 'constants/index'
import { secToLabel } from 'utils/secToLabel'
import { playlistTypes } from 'constants/playlist'
import { getUrlPrefix } from 'utils'
import { getPlaylistPreview } from 'actions/playlistActions'

const styles = ({ palette, type, typography }) => ({
  listItem: {
    padding: '5px 5px 5px 16px',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`
  },
  checkbox: {
    margin: '0 0 0 auto'
  },
  tooltip: {
    margin: '5px 20px',
    '&:after': {
      borderTop: 'none'
    }
  },
  tooltipWithoutSelect: {
    margin: '5px 20px 5px 0px'
  },
  title: {
    ...typography.darkAccent[type],
    textDecoration: 'none'
  },
  textSmall: {
    ...typography.subtitle[type]
  },
  dateWrap: {
    textAlign: 'center',
    marginRight: '30px'
  },
  faded: {
    opacity: 0.5
  },
  pointer: {
    cursor: 'pointer'
  }
})

const getPlaylistInfo = type => playlistConstants.playlistTypes[type] || {}

const PlaylistItem = ({
  classes,
  handleSelectedChange,
  withLink,
  data,
  selectType = 'checkbox',
  selected,
  disabled
}) => {
  const dispatch = useDispatch()
  const { id, title, playlistType: type, updatedAt, durationTotal } = data

  const isItemDisabled = useMemo(
    () =>
      disabled || (selectType !== 'none' && type === playlistTypes.Smart.name),
    [disabled, type, selectType]
  )

  const renderTitle = useMemo(() => {
    return withLink ? (
      <Link
        to={getUrlPrefix(`${routeByName.playlist.root}?id=${id}&exact=true`)}
        target="_blank"
        className={classNames(classes.title, classes.pointer)}
      >
        <TextWithTooltip
          maxWidth={250}
          placement="bottom"
          color="title.primary"
          weight="bold"
        >
          {title}
        </TextWithTooltip>
      </Link>
    ) : (
      <TextWithTooltip
        maxWidth={250}
        placement="bottom"
        rootClassName={classes.title}
        color="title.primary"
        weight="bold"
      >
        {title}
      </TextWithTooltip>
    )
  }, [id, title, withLink, classes])

  const handlePreviewClick = useCallback(() => {
    dispatch(getPlaylistPreview(id))
  }, [id, dispatch])

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      key={id}
      className={classes.listItem}
    >
      <Grid
        item
        className={classNames({
          [classes.faded]: isItemDisabled
        })}
      >
        <Grid container alignItems="center">
          {selectType === 'checkbox' && (
            <Checkbox
              disabled={isItemDisabled}
              value={String(id)}
              checked={selected}
              onChange={handleSelectedChange}
              className={classes.checkbox}
            />
          )}
          {selectType === 'radio' && (
            <Radio
              disabled={isItemDisabled}
              checked={selected}
              onClick={handleSelectedChange}
            />
          )}
          <Tooltip
            arrow
            withWrapper
            title={getPlaylistInfo(type).title}
            className={classNames(classes.tooltip, {
              [classes.tooltipWithoutSelect]: selectType === 'none'
            })}
          >
            <LibraryTypeIcon
              color={getPlaylistInfo(type).color}
              icon={getPlaylistInfo(type).icon}
              iconHelperClass={getPlaylistInfo(type).iconHelperClass}
              onClick={handlePreviewClick}
            />
          </Tooltip>
          <div>
            {renderTitle}
            <Typography className={classes.textSmall}>
              {durationTotal === 0 ? 'N/A' : secToLabel(durationTotal)}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        className={classNames(classes.dateWrap, {
          [classes.faded]: isItemDisabled
        })}
      >
        <DateTimeView date={updatedAt} />
      </Grid>
    </Grid>
  )
}

PlaylistItem.propTypes = {
  selectType: PropTypes.oneOf(['checkbox', 'radio', 'none']),
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  withLink: PropTypes.bool
}

export default withStyles(styles)(memo(PlaylistItem))
