import React, { useCallback, useState, useMemo } from 'react'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'
import { Grid, withStyles, withTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Popup from 'components/Popup'
import { popupPosition as positionConstants } from 'constants/stylesConstants'
import { BACKEND_DATE_FORMAT } from 'constants/dateTimeFormats'
import { simulateEvent } from 'utils'

import 'react-dates/lib/css/_datepicker.css'
import 'styles/forms/_datepicker.scss'
import MaterialPopup from 'components/Popup/MaterialPopup'
import DateField from './formControlDateTimePickers/components/DateField'

const styles = () => ({
  inputContainer: {
    width: '100%',
    cursor: 'pointer'
  },
  inputInput: {
    cursor: 'pointer'
  },
  inputIcon: {
    color: '#74809a',
    position: 'absolute',
    right: 10,
    top: 9
  },
  button: {
    padding: 0,
    margin: 0,
    position: 'relative',
    background: 'transparent',
    border: 0,
    outline: 'none'
  },
  marginL: {
    marginLeft: 10
  },
  marginBottom: {
    marginBottom: 16
  },
  clearIcon: {
    position: 'absolute',
    top: 10,
    right: 34,
    fontSize: 13,
    color: '#74809a',
    cursor: 'pointer'
  },
  disablePointerEvents: {
    pointerEvents: 'none'
  },
  labelRoot: {
    fontSize: 13,
    lineHeight: '22px',
    fontWeight: 400,
    color: '#74809A',
    transform: 'none'
  },
  root: {
    display: 'flex'
  },
  topLabel: {
    flexDirection: 'column'
  },
  bottomLabel: {
    flexDirection: 'column-reverse'
  },
  leftLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > div:first-child': {
      paddingRight: 5
    }
  },
  rightLabel: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div:first-child': {
      paddingLeft: 5
    }
  }
})

const isCurrentOrFutureRange = day => moment().diff(day) > 0

const FormControlSingleDatePicker = ({
  classes,
  label,
  value,
  handleChange = f => f,
  isOptional = false,
  error,
  touched,
  classContainer,
  buttonWrapper,
  name,
  format = BACKEND_DATE_FORMAT,
  placeholder,
  noMargin,
  disabled,
  popupPosition = positionConstants.BOTTOM_RIGHT,
  isClearable,
  offsetY,
  theme,
  isOutsideRange = isCurrentOrFutureRange,
  withPortal = false,
  labelPosition = 'top',
  isEditable = false,
  ...props
}) => {
  const { t } = useTranslation()

  const [isDatepickerEdit, setIsDatePickerEdit] = useState(false)

  const updateFormikValue = useCallback(
    date => {
      if (date) {
        handleChange(simulateEvent(name, moment(date, format).format(format)))
      }
    },
    [format, handleChange, name]
  )

  const handleDateChange = useCallback(
    date => {
      if (isEditable) {
        updateFormikValue(date)
      } else {
        handleChange(simulateEvent(name, date))
      }
    },
    [handleChange, isEditable, name, updateFormikValue]
  )

  const handleFocus = useCallback(
    event => {
      const {
        target: { name }
      } = event

      if (isEditable) {
        setIsDatePickerEdit(name)
      }
    },
    [isEditable]
  )

  const handleBlur = useCallback(
    event => {
      const {
        target: { value }
      } = event
      if (value) {
        const parsedMoment = moment(value, format, true)
        if (parsedMoment.isValid()) {
          updateFormikValue(parsedMoment)
        }
        setIsDatePickerEdit('')
      }
    },
    [format, updateFormikValue]
  )

  const onBlur = useCallback(() => {
    handleBlur(simulateEvent(name, null))
  }, [handleBlur, name])

  const clearValue = useCallback(
    event => {
      if (isEditable) {
        const inputElement = document.querySelector(`input[name="${name}"]`)
        if (inputElement) {
          handleChange({ target: { name, value: null } })
          inputElement.focus()
        }
      } else {
        handleChange({ target: { name, value: '' } })
        event.stopPropagation()
      }
    },
    [handleChange, isEditable, name]
  )

  const CustomPopup = useMemo(() => {
    return ({ children, trigger }) =>
      withPortal ? (
        <MaterialPopup
          on="click"
          placement={popupPosition}
          offset={offsetY}
          disabled={disabled}
          onClose={onBlur}
          style={{ minHeight: 300 }}
          trigger={trigger}
          hasArrow
          preventOverflow={{
            enabled: true,
            boundariesElement: 'viewport'
          }}
        >
          {children}
        </MaterialPopup>
      ) : (
        <Popup
          on="click"
          position={popupPosition}
          offsetY={offsetY}
          disabled={disabled}
          onClose={onBlur}
          contentStyle={{ minHeight: 300 }}
          trigger={trigger}
        >
          {children}
        </Popup>
      )
  }, [withPortal, popupPosition, offsetY, onBlur, disabled])

  const parseDateValue = useMemo(() => {
    return value === null
      ? value
      : typeof value === 'string'
      ? value
      : value?.format(format)
  }, [format, value])

  const calanderDateValue = useMemo(() => {
    if (!value) return null
    const parsedDate =
      typeof value === 'string' ? moment(value, format, true) : value
    return parsedDate.isValid() ? parsedDate : null
  }, [format, value])

  return (
    <Grid
      container
      className={classNames(classes.root, classContainer, {
        [classes.marginBottom]: !noMargin,
        [classes.leftLabel]: labelPosition === 'left',
        [classes.topLabel]: labelPosition === 'top',
        [classes.bottomLabel]: labelPosition === 'bottom',
        [classes.rightLabel]: labelPosition === 'right'
      })}
    >
      <div className={classes.labelRoot}>
        {label} {isOptional && <i>({t('optional')})</i>}
      </div>
      <CustomPopup
        trigger={
          <div className={classNames(classes.button, buttonWrapper)}>
            <DateField
              onChange={(name, value) =>
                handleChange(simulateEvent(name, value))
              }
              labelPosition={labelPosition}
              endAdornment={
                <i
                  className={classNames(
                    'fa-regular fa-calendar-days',
                    classes.inputIcon,
                    {
                      [classes.disablePointerEvents]: disabled
                    }
                  )}
                />
              }
              name={name}
              placeholder={format}
              value={parseDateValue}
              onFocusEvent={handleFocus}
              onBlurEvent={handleBlur}
              fullWidth
              marginBottom={false}
              readOnly={!isEditable}
              mask={format}
              autocomplete="off"
              isDatepickerEdit={isDatepickerEdit}
              disabled={disabled}
              {...props}
            />
            {isClearable && value && (
              <i
                className={classNames('fa-regular fa-xmark', classes.clearIcon)}
                onClick={clearValue}
              />
            )}
          </div>
        }
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classNames({
            'DatePicker-dark': theme.type === 'dark'
          })}
        >
          <DayPickerSingleDateController
            date={calanderDateValue}
            onDateChange={handleDateChange}
            {...(isOutsideRange && { isOutsideRange })}
            focused
          />
        </Grid>
      </CustomPopup>
    </Grid>
  )
}

export default withTheme(withStyles(styles)(FormControlSingleDatePicker))
