import { createAppApi } from 'services/api'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { queryParamsHelper } from '../utils'
import apiTags from 'constants/apiTags'
import { createSuccessInvalidator } from 'utils/apiUtils'

const sanitizeMediasParams = params => ({
  ...queryParamsHelper(
    params,
    ['group', 'tag'],
    [],
    ['featureId', 'approvedStatus']
  ),
  ...(params?.status === 'Disabled' && {
    featureEnabled: 0,
    status: null
  })
})

export const mediaApi = createAppApi('mediaQuery', {
  tagTypes: [apiTags.mediaLibrary],
  endpoints: builder => ({
    validateUrlIframeHeader: builder.mutation({
      query: (data = {}) => ({
        url: '/media/headerValidation',
        method: 'POST',
        data
      })
    }),
    copyMedias: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/media/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/media/bulkCopy'))
        }
      }
    }),
    shareMedias: builder.mutation({
      query: (data = {}) => ({
        url: '/media/bulkShare',
        method: 'POST',
        data
      })
    }),
    publishDesigns: builder.mutation({
      query: (data = {}) => ({
        url: '/media/bulkPublishDesignGalleryMedia',
        method: 'PUT',
        data
      })
    }),
    publishDesign: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/media/${id}/publishDesignGalleryMedia`,
        method: 'PUT',
        data
      })
    }),
    bulkAssignDesignGalleryCategories: builder.mutation({
      query: (data = {}) => ({
        url: '/designGallery/design/bulkAssignDesignGalleryCategories',
        method: 'POST',
        data
      })
    }),
    updateDesignMetadata: builder.mutation({
      query: ({ designId, ...data }) => ({
        url: `designGallery/design/${designId}/metadata`,
        method: 'PUT',
        data
      })
    }),
    getMediaLibraryItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media',
        params: sanitizeMediasParams(params)
      })
    }),
    getMediaGridItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media/gridView',
        params: sanitizeMediasParams(params)
      })
    }),
    getMediaGridEmbeddedItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media/gridViewEmbedded',
        params: sanitizeMediasParams(params)
      })
    }),
    getMediaListItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media/listView',
        params: sanitizeMediasParams(params)
      })
    }),
    getMediaLibraryItem: builder.query({
      query: id => ({
        method: 'GET',
        url: `/media/${id}`
      })
    })
  })
})

export const {
  useValidateUrlIframeHeaderMutation,
  useCopyMediasMutation,
  useShareMediasMutation,
  usePublishDesignsMutation,
  usePublishDesignMutation,
  useBulkAssignDesignGalleryCategoriesMutation,
  useUpdateDesignMetadataMutation,
  useLazyGetMediaLibraryItemsQuery,
  useLazyGetMediaGridItemsQuery,
  useLazyGetMediaGridEmbeddedItemsQuery,
  useLazyGetMediaListItemsQuery,
  useGetMediaListItemsQuery,
  useLazyGetMediaLibraryItemQuery,
  useGetMediaLibraryItemQuery
} = mediaApi
