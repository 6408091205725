import { createAppApi } from 'services/api'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { getCRUDEndpoints } from '../utils/apiUtils'
import apiTags from '../constants/apiTags'

export const schedulesApi = createAppApi('schedulesQuery', {
  tagTypes: [apiTags.schedule],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      url: '/schedule',
      name: 'Schedule',
      tags: [apiTags.schedule]
    }),
    copySchedules: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/schedule/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/schedule/bulkCopy'))
        }
      }
    })
  })
})

export const {
  useCopySchedulesMutation,
  useLazyGetSchedulesQuery
} = schedulesApi
