import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { _isArray, _concat, _uniqBy } from 'utils/lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, withStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import classNames from 'classnames'
import _sortBy from 'lodash/sortBy'
import FlipMove from 'react-flip-move'
import BaseSection from './BaseSection'
import { DeviceFilter } from './FilterForms'
import FormControlSelectDevice from 'components/Form/FormControlSelectDevice'
import { DeviceRow } from './Rows'
import { ScheduleDeviceFeaturesLoader } from 'components/Loaders/DeviceFeaturesLoader'
import Scrollbars from 'components/Scrollbars'
import handleBottomScroll from 'utils/handleBottomScroll'
import Container from 'components/Containers/Container'
import { anyParamsModifier } from 'utils/filterUtils'
import { deviceLibraryInitialFilter } from 'reducers/filters'
import { isNumber } from 'utils/generalUtils'
import useFilter from 'hooks/useFilter'
import entityConstants from 'constants/entityConstants'
import { scheduledDeviceIdsSelector } from 'selectors/deviceSelectors'
import {
  clearGetDeviceItemsAction,
  getScheduledDeviceIds,
  resetDeviceFetchParamsAction
} from 'actions/deviceActions'
import { queryParamsHelper } from 'utils/index'
import { scheduleItemSelector } from 'selectors/scheduleSelectors'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import { isAutoAddedDevice } from 'utils/scheduleUtils'
import { scheduleTypes } from 'constants/schedule'
import { BlueButton, CircleIconButton, WhiteButton } from 'components/Buttons'
import DefaultModal from 'components/Modal/DefaultModal'
import ModalDeviceRow from './Rows/ModalDeviceRow'
import moment from 'moment'
import { MEDIA_DATE_FORMAT } from 'constants/dateTimeFormats'
import { useLazyDeviceListQuery } from 'api/deviceApi'

const styles = ({ palette, type }) => ({
  bodyWrapperContent: {
    flex: 1
  },
  scheduleInfoWrap: {
    padding: '20px 20px 0',
    height: '100%'
  },
  mediaItemsWrap: {
    margin: 0,
    height: '100%',
    position: 'relative'
  },
  contentListWrap: {
    height: ' max-content'
  },
  modalContentListWrap: {
    height: ' max-content',
    width: '95%',
    margin: '0 auto'
  },
  loaderBackgroundWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    background: palette[type].card.greyHeader.background,
    overflow: 'hidden'
  },
  modalLoaderBackgroundWrap: {
    height: '100%',
    background: palette[type].loader.background,
    overflow: 'hidden'
  },
  flipMode: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'inherit'
  },
  modalFlipMode: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(165px, 1fr) )',
    gap: '15px'
  },
  circleIcon: {
    marginTop: '15px',
    height: 'fit-content',
    width: 'fit-content'
  },
  modalItemsWrap: {
    height: 'calc(100vh - 185px)',
    background: palette[type].modal.background,
    padding: '20px 20px'
  },
  modalRoot: {
    height: '100%'
  },
  dialogFooter: {
    borderTop: `1px solid ${palette[type].sideModal.content.border}`
  },
  loadMoreButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '15px',
    paddingBottom: '15px'
  }
})

const rows = 12

const NUMBER_OF_DEVICE_TO_SHOW = 24

const deviceFetchParams = {
  sort: 'alias',
  order: 'asc'
}

const DeviceSection = ({
  startDate,
  endDate,
  selectedDevices,
  handleSelectedDevicesChange,
  assignType,
  disableTypeSelection,
  fullWidth,
  withHeaderCard,
  cols = 2,
  colGridSize = 6,
  maxTitleWidth,
  loader: Loader = ScheduleDeviceFeaturesLoader,
  onChange,
  hideSchedulePopup,
  classes,
  emptyPlaceholderRootClass = '',
  rootClassName = '',
  error,
  t,
  hasSelectedItems,
  scheduleType
}) => {
  const dispatch = useDispatch()
  const scheduleItem = useSelector(scheduleItemSelector)
  const { response: scheduledDeviceIds } = useSelector(
    scheduledDeviceIdsSelector
  )

  const [filterParams, updateFilters, resetFilters] = useFilter(
    entityConstants.DeviceLibrary
  )

  const [deviceList, setDeviceList] = useState([])
  const [deviceModal, setDeviceModal] = useState(false)
  const [modalDeviceList, setModalDeviceList] = useState([])
  const [modalSelectedDevices, setModalSelectedDevices] = useState([])
  const modifiedQueryParams = useMemo(
    () => anyParamsModifier(filterParams, ['status'], true),
    [filterParams]
  )

  const currentScrollPosition = useRef(0)
  const scrollBarRef = useRef()

  const handleScrollUpdate = useCallback(({ scrollTop }) => {
    currentScrollPosition.current = scrollTop
  }, [])

  const itemsLimit = useMemo(() => rows * cols, [cols])

  const [
    fetchBasicDevices,
    { data: { data: response = [], meta = {} } = {}, isFetching }
  ] = useLazyDeviceListQuery()

  const fetchDevices = useCallback(
    (params = {}) => {
      fetchBasicDevices(
        queryParamsHelper(
          {
            limit: itemsLimit,
            ...deviceFetchParams,
            ...params
          },
          ['group', 'tag'],
          [],
          ['mediaFeature']
        )
      )
    },
    [fetchBasicDevices, itemsLimit]
  )

  const fetchScheduledDevices = (params = {}) => {
    if (moment(startDate, MEDIA_DATE_FORMAT, true).isValid()) {
      dispatch(
        getScheduledDeviceIds(
          queryParamsHelper(
            {
              ...deviceFetchParams,
              ...filterParams,
              'startDate-from': startDate.format('YYYY-MM-DD'),
              ...(endDate &&
                moment(endDate, MEDIA_DATE_FORMAT, true).isValid() && {
                  'endDate-to': endDate
                }),
              ...params,
              mediaFeature: null
            },
            ['group', 'tag']
          )
        )
      )
    }
  }
  const isLastPage = useMemo(() => meta.currentPage >= meta.lastPage, [
    meta.currentPage,
    meta.lastPage
  ])

  const handleFetchMoreModal = useCallback(() => {
    if (!isLastPage) {
      fetchDevices({
        ...filterParams,
        page: meta.currentPage + 1,
        limit: 100
      })
    }

    // eslint-disable-next-line
  }, [isLastPage, filterParams, fetchDevices])

  const handleSubmitFilter = useCallback(
    values => {
      const modifiedValues = anyParamsModifier(
        { ...deviceLibraryInitialFilter, ...values },
        ['status'],
        false
      )

      const updateValues = { ...modifiedValues }
      if (!isNumber(updateValues?.clientId)) {
        updateValues.clientName = updateValues?.clientId
        updateValues.clientId = ''
      } else {
        updateValues.clientName = ''
      }

      setDeviceList([])
      updateFilters(updateValues)
      fetchDevices(updateValues)
    },
    [updateFilters, fetchDevices]
  )

  const handleResetFilter = useCallback(() => {
    setDeviceList([])
    resetFilters()
    fetchDevices()
  }, [resetFilters, fetchDevices])

  const {
    values: filterValues,
    setFieldValue,
    handleSubmit,
    setValues,
    handleChange,
    handleReset
  } = useFormik({
    initialValues: modifiedQueryParams,
    onSubmit: handleSubmitFilter,
    onReset: handleResetFilter
  })

  const handleNameChange = useCallback(
    ({ target: { value, __isNew__, label } }) => {
      setFieldValue('id', __isNew__ ? '' : value)
      setFieldValue('name', label)
      handleSubmit()
    },
    [setFieldValue, handleSubmit]
  )

  useEffect(
    () => {
      resetFilters()
      fetchDevices()
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    return () => {
      dispatch(resetDeviceFetchParamsAction())
      dispatch(clearGetDeviceItemsAction())
      resetFilters()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!response.length || isFetching) return
    if (meta.perPage === 100) {
      if (meta.currentPage > 1) {
        setModalDeviceList(prevState => prevState.concat(response))
        scrollBarRef.current?.scrollToBottom(currentScrollPosition.current)
      } else {
        setModalDeviceList(response)
      }
    } else {
      if (meta.currentPage > 1) {
        setDeviceList(prevState => prevState.concat(response))
      } else {
        if (modalSelectedDevices.length === 0) setDeviceList(response)
        else {
          const list = _uniqBy(_concat(response, modalSelectedDevices), 'id')
          setDeviceList(list)
        }
      }
    }
  }, [
    isFetching,
    meta.currentPage,
    meta.perPage,
    modalSelectedDevices,
    response
  ])

  useEffect(() => {
    setValues(anyParamsModifier(filterParams, ['status'], true))
    // eslint-disable-next-line
  }, [filterParams])

  useEffect(
    () => {
      if (deviceList.length && !isFetching) {
        fetchScheduledDevices({
          limit: meta.currentPage * meta.perPage
        })
      }
    },
    // eslint-disable-next-line
    [startDate, endDate]
  )

  useEffect(() => {
    if (response && !isFetching) {
      fetchScheduledDevices({
        page: meta.currentPage,
        limit: meta.perPage
      })
    }
    // eslint-disable-next-line
  }, [response])

  const isFilterActive = useMemo(() => {
    return Object.values(filterValues).some(value => {
      if (value === 'any' || '0') return false
      if (_isArray(value)) return value.length > 0
      if (value === 0) return false
      return !!value
    })
  }, [filterValues])

  const backendSelectedDevices = useMemo(
    () => scheduleItem?.response?.deviceList || [],
    [scheduleItem]
  )

  const orderedDeviceList = useMemo(() => {
    const data = deviceList.map(device => {
      if (selectedDevices.includes(device.id)) {
        return { ...device, selected: true }
      } else {
        return device
      }
    })

    return _sortBy(data, ['selected'])
  }, [deviceList, selectedDevices])

  const uniqueDevices = useMemo(
    () =>
      isFilterActive
        ? orderedDeviceList
        : _uniqBy(_concat(backendSelectedDevices, orderedDeviceList), 'id'),
    [isFilterActive, orderedDeviceList, backendSelectedDevices]
  )
  const modalOrderedDeviceList = useMemo(() => {
    const data = modalDeviceList.map(device => {
      if (selectedDevices.includes(device.id)) {
        return { ...device, selected: true }
      } else {
        return device
      }
    })

    return data
  }, [modalDeviceList, selectedDevices])

  const modalUniqueDevices = useMemo(
    () =>
      isFilterActive
        ? modalOrderedDeviceList
        : _uniqBy(
            _concat(backendSelectedDevices, modalOrderedDeviceList),
            'id'
          ),
    [isFilterActive, modalOrderedDeviceList, backendSelectedDevices]
  )

  const renderDevices = useMemo(() => {
    const devices =
      modalSelectedDevices.length >= itemsLimit
        ? modalSelectedDevices
        : uniqueDevices

    return devices.slice(0, NUMBER_OF_DEVICE_TO_SHOW).map(item => {
      const autoAdded = isAutoAddedDevice(
        item.id,
        scheduleItem?.response?.scheduleDevice
      )
      const disabled = autoAdded && scheduleType !== scheduleTypes.Failover.name

      return (
        <Grid item xs={colGridSize} key={item.id}>
          <DeviceRow
            {...(disabled && {
              disabledReason: t('Auto added via Group / Tag / Location')
            })}
            disabled={disabled}
            gridSize={12}
            item={item}
            key={item.id}
            name={item.alias || item.name}
            maxTitleWidth={maxTitleWidth}
            isScheduled={scheduledDeviceIds.includes(item.id)}
            checkboxValue={!!autoAdded || selectedDevices?.includes(item.id)}
            onChange={handleSelectedDevicesChange}
            hideSchedulePopup={hideSchedulePopup}
          />
        </Grid>
      )
    })
  }, [
    uniqueDevices,
    scheduleItem?.response?.scheduleDevice,
    scheduleType,
    colGridSize,
    t,
    maxTitleWidth,
    scheduledDeviceIds,
    selectedDevices,
    handleSelectedDevicesChange,
    hideSchedulePopup,
    modalSelectedDevices,
    itemsLimit
  ])

  const filterComponent = useMemo(
    () => (
      <DeviceFilter
        values={filterValues}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onReset={handleReset}
      />
    ),
    [filterValues, handleChange, handleSubmit, handleReset]
  )

  const titleSearchComponent = useMemo(
    () => (
      <FormControlSelectDevice
        name="name"
        label={''}
        onChange={handleNameChange}
        placeholder={t('Search')}
        value={filterValues.name}
        marginBottom={false}
        isClearable
        hasCopy
        returnId
        useLabel
      />
    ),
    [handleNameChange, filterValues, t]
  )

  const openDeviceModalHandler = () => {
    fetchDevices({ limit: 100 })
    setDeviceModal(prev => !prev)
  }

  const closeDeviceModalHandler = () => {
    if (deviceModal) {
      const devices = []
      selectedDevices.forEach(id => {
        const device = modalDeviceList.find(device => device.id === id)
        devices.push({ ...device, selected: true })
      })
      setModalSelectedDevices(devices)

      const shownDeviceIds = uniqueDevices.map(({ id }) => id)
      let numberOfNewDevices = 0

      devices.forEach(selectedDevice => {
        if (!shownDeviceIds.includes(selectedDevice.id)) {
          numberOfNewDevices++
        }
      })

      fetchDevices({
        limit:
          itemsLimit - numberOfNewDevices <= 0
            ? 1
            : itemsLimit - numberOfNewDevices
      })
      setDeviceModal(prev => !prev)
      handleScrollUpdate({ scrollTop: 0 })
    }
  }

  return (
    <>
      <BaseSection
        filterComponent={filterComponent}
        titleSearchComponent={titleSearchComponent}
        title={t('Select Devices')}
        assignType={assignType}
        disableTypeSelection={disableTypeSelection}
        onChange={onChange}
        fullWidth={fullWidth}
        withHeaderCard={withHeaderCard}
        rootClassName={rootClassName}
        error={error}
        hasSelectedItems={hasSelectedItems}
        scheduleType={scheduleType}
      >
        <div className={classes.bodyWrapperContent}>
          <div className={classNames(classes.mediaItemsWrap, 'popupBoundary')}>
            {(isFetching || scheduleItem.isFetching) && (
              <div className={classes.loaderBackgroundWrap}>
                <Loader />
              </div>
            )}

            {!isFetching && !response.length ? (
              <EmptyPlaceholder
                text={t('No Results Found')}
                rootClassName={emptyPlaceholderRootClass}
              />
            ) : (
              <Scrollbars>
                <Container className={classes.scheduleInfoWrap}>
                  <Grid
                    container
                    className={classes.contentListWrap}
                    spacing={3}
                  >
                    <FlipMove
                      duration={400}
                      easing="ease-in-out"
                      className={classes.flipMode}
                    >
                      {renderDevices}
                    </FlipMove>
                    {meta?.currentPage !== meta?.lastPage && (
                      <Grid item container justifyContent="center">
                        <CircleIconButton
                          onClick={openDeviceModalHandler}
                          className={classNames(
                            `hvr-grow ${classes.circleIcon}`
                          )}
                        >
                          <i className="icon-add-circle-1" />
                        </CircleIconButton>
                      </Grid>
                    )}
                  </Grid>
                </Container>
              </Scrollbars>
            )}
          </div>
        </div>
      </BaseSection>
      <DefaultModal
        hasCancelBtn={false}
        hasSaveBtn={false}
        overflowVisible
        modalTitle={'Select Devices'}
        open={deviceModal}
        maxWidth={'xl'}
        onCloseModal={closeDeviceModalHandler}
        contentClass={classNames(
          {
            [classes.modalItemsWrap]: !(isFetching || scheduleItem.isFetching)
          },
          {
            [classes.modalLoaderBackgroundWrap]:
              isFetching || scheduleItem.isFetching
          }
        )}
        rootClassName={classes.modalRoot}
        footerClassName={classes.dialogFooter}
        actions={
          <WhiteButton onClick={closeDeviceModalHandler}>
            {t('Select')}
          </WhiteButton>
        }
      >
        <>
          {isFetching || scheduleItem.isFetching ? (
            <div className={classes.modalLoaderBackgroundWrap}>
              <Loader />
            </div>
          ) : (
            <Scrollbars
              ref={scrollBarRef}
              onUpdate={handleBottomScroll(handleScrollUpdate)}
            >
              <div className={classes.modalContentListWrap}>
                <div className={classes.modalFlipMode}>
                  {modalUniqueDevices.map((item, index) => {
                    const autoAdded = isAutoAddedDevice(
                      item.id,
                      scheduleItem?.response?.scheduleDevice
                    )
                    const disabled =
                      autoAdded && scheduleType !== scheduleTypes.Failover.name

                    return (
                      <div key={item.id}>
                        <ModalDeviceRow
                          {...(disabled && {
                            disabledReason: t(
                              'Auto added via Group / Tag / Location'
                            )
                          })}
                          disabled={disabled}
                          gridSize={12}
                          item={item}
                          key={item.id}
                          name={item.alias || item.name}
                          isScheduled={scheduledDeviceIds.includes(item.id)}
                          checkboxValue={
                            !!autoAdded || selectedDevices?.includes(item.id)
                          }
                          onChange={handleSelectedDevicesChange}
                          hideSchedulePopup={hideSchedulePopup}
                          modalView
                        />
                      </div>
                    )
                  })}
                </div>
                {!isLastPage && (
                  <div className={classes.loadMoreButton}>
                    <BlueButton
                      disabled={false}
                      onClick={handleFetchMoreModal}
                      iconClassName="fa-solid fa-angles-down"
                    >
                      {t('Show More')}
                    </BlueButton>
                  </div>
                )}
              </div>
            </Scrollbars>
          )}
        </>
      </DefaultModal>
    </>
  )
}

export default withStyles(styles)(
  withTranslation('translations')(DeviceSection)
)
