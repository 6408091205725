import React, { useCallback, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import { FormControlInput } from 'components/Form'
import { BlueButton } from 'components/Buttons'
import { ipRegExp } from 'constants/regExp'
import { TableLibraryRowActionButton } from 'components/TableLibrary'
import { TextWithTooltip } from 'components/Typography'

const styles = ({ palette, type }) => ({
  root: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rootWithError: {
    marginBottom: 22
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10
  },
  ip: {
    color: palette[type].formControls.input.color
  },
  icon: {
    marginRight: '20px',
    color: palette[type].formControls.input.color
  },
  controlInput: {
    flexGrow: 2,
    marginRight: '10px'
  },
  column: {
    display: 'flex',
    alignItems: 'center'
  }
})

const columnWidth = 248

const UrlCard = ({
  classes,
  index,
  t,
  values,
  onDelete = f => f,
  onUpdate = f => f,
  updateResult
}) => {
  const [IP, setIP] = useState(values.ip)
  const [title, setTitle] = useState(values.title)
  const [error, setError] = useState({ ip: '', title: '' })
  const [isActiveEdit, toggleActiveEdit] = useState(false)

  const handleClickDelete = useCallback(() => {
    onDelete(index)
  }, [index, onDelete])

  const handleChange = useCallback(({ target: { name, value } }) => {
    name === 'title' ? setTitle(value) : setIP(value)
    setError({ ip: '', title: '' })
  }, [])

  const handleClickEdit = useCallback(() => {
    if (isActiveEdit) {
      if (IP && IP.match(ipRegExp) && !!title) {
        setError({ ip: '', title: '' })
        onUpdate({
          id: index,
          title,
          ip: IP
        })
      }

      if (!IP || !IP.match(ipRegExp)) {
        setError(prev => ({ ...prev, ip: 'Please enter valid IP Address' }))
      }

      if (!title) {
        setError(prev => ({ ...prev, title: 'Name field is required' }))
      }
    } else {
      toggleActiveEdit(true)
    }
  }, [IP, title, index, isActiveEdit, onUpdate])

  useEffect(() => {
    if (
      updateResult?.originalArgs?.id === index &&
      updateResult?.error?.errorFields
    ) {
      let err = {}
      updateResult.error.errorFields.forEach(error => {
        err[error.name] = error.value[0]
      })

      if (err.title) {
        setError(prev => ({ ...prev, title: err.title }))
      }
      if (err.ip) {
        setError(prev => ({ ...prev, ip: err.ip }))
      }
    }
    // eslint-disable-next-line
  }, [updateResult?.error?.errorFields])

  useEffect(() => {
    if (updateResult?.isSuccess) {
      toggleActiveEdit(false)
    }
  }, [updateResult?.isSuccess])

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootWithError]: error.ip || error.title
      })}
    >
      {isActiveEdit ? (
        <>
          <FormControlInput
            name="ip"
            value={IP}
            handleChange={handleChange}
            formControlContainerClass={classes.controlInput}
            error={error.ip}
            marginBottom={false}
            touched
          />
          <FormControlInput
            name="title"
            value={title}
            handleChange={handleChange}
            formControlContainerClass={classes.controlInput}
            error={error.title}
            marginBottom={false}
            touched
          />
        </>
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.column} style={{ width: columnWidth }}>
            <TextWithTooltip maxWidth={columnWidth} rootClassName={classes.ip}>
              {IP}
            </TextWithTooltip>
          </div>

          <div className={classes.column} style={{ width: columnWidth }}>
            <TextWithTooltip maxWidth={columnWidth} rootClassName={classes.ip}>
              {title}
            </TextWithTooltip>
          </div>
        </div>
      )}
      {isActiveEdit ? (
        <BlueButton
          className={classes.submit}
          type="submit"
          onClick={handleClickEdit}
          iconClassName="fa-regular fa-circle-plus"
        >
          {t('Update')}
        </BlueButton>
      ) : (
        <TableLibraryRowActionButton
          actionLinks={[
            {
              label: t('Edit'),
              clickAction: handleClickEdit
            },
            { divider: true },
            {
              label: t('Delete'),
              icon: 'fa-regular fa-trash-can',
              clickAction: handleClickDelete
            }
          ]}
          position="bottom right"
        />
      )}
    </div>
  )
}

export default withTranslation('translations')(withStyles(styles)(UrlCard))
