import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { getCRUDEndpoints } from 'utils/apiUtils'

export const eulaTermsApi = createAppApi('eulaTermsQuery', {
  tagTypes: [apiTags.eulaTerms],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'EulaTerm',
      url: '/EULAVersion',
      builder,
      tags: [apiTags.eulaTerms]
    }),
    getAcceptedEulaTermsByUser: builder.query({
      query: ({ id, params }) => {
        return {
          url: `/user/${id}/eula`,
          params
        }
      }
    }),
    validateEulaTermPreview: builder.mutation({
      query: data => {
        return {
          method: 'POST',
          url: '/EULAVersion/preview',
          data
        }
      }
    })
  })
})

export const {
  useLazyGetEulaTermsQuery,
  useLazyGetAcceptedEulaTermsByUserQuery,
  usePostEulaTermMutation,
  useValidateEulaTermPreviewMutation,
  useGetEulaTermQuery,
  useDeleteEulaTermMutation,
  usePutEulaTermMutation,
  useGetEulaTermsQuery
} = eulaTermsApi
