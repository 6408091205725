import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core'
import moment from 'moment'
import classNames from 'classnames'

import { simulateEvent } from 'utils/formik'
import { labelToSec } from 'utils/secToLabel'
import {
  AM_PM_FORMAT,
  AM_PM_S_FORMAT,
  TIME_FORMAT,
  TIME_S_FORMAT
} from 'constants/dateTimeFormats'
import Toolbar from './Toolbar'
import styles from '../styles'
import FormControlMaterialDateTimeWrap from '../FormControlMaterialDateTimeWrap'

const customStyle = ({ fontSize }) => ({
  timePicker: {
    '& [class*=MuiPickersBasePicker-containerLandscape]': {
      height: 250
    },
    '& [class*=MuiPickersBasePicker-pickerView]': {
      maxWidth: 292,
      width: 292,
      minHeight: 245,
      overflowX: 'unset',
      transform: 'scale(0.85)',
      margin: '0 -28px'
    },
    '& [classes*=MuiPickersToolbarText-toolbarTxt]': {
      fontSize: '44px'
    }
  },
  inputSmall: {
    fontSize: fontSize.small
  },
  inputSmallest: {
    fontSize: fontSize.smallest
  }
})

const FormControlTimeDurationPicker = ({
  id,
  name,
  withSeconds = true,
  label,
  labelPosition,
  tooltip,
  tooltipHeader,
  value,
  setValue,
  min = '00:00:00',
  max = '23:59:59',
  disabled,
  hideIcon,
  onChange,
  error,
  touched,
  onClickLabel,
  fullWidth = true,
  marginBottom,
  fontSizeVariant,
  labelFontSizeVariant,
  containerRootClassName,
  inputRootClassName,
  inputTextRootClassName,
  labelRootClassName,
  classes,
  greaterThan24 = false,
  invalidDateMessage = '',
  errorClassNames,
  displayWithSeconds = true,
  anchorReference = 'anchorPosition',
  variant = 'inline',
  orientation = 'landscape',
  hasToolbar = true,
  ampm = false,
  isOptional
}) => {
  const ref = useRef(null)
  const [isPickerOpen, setPickerOpen] = useState(false)
  const [hasError, setHasError] = useState(null)

  const format = useMemo(
    () =>
      ampm
        ? withSeconds && displayWithSeconds
          ? AM_PM_S_FORMAT
          : AM_PM_FORMAT
        : withSeconds && displayWithSeconds
        ? TIME_S_FORMAT
        : TIME_FORMAT,
    [withSeconds, ampm, displayWithSeconds]
  )

  const parseValue = useCallback(value => moment(value, format), [format])

  const getValueInRange = useCallback(
    value => {
      if (!value) {
        return moment(min, TIME_S_FORMAT).format(format)
      } else if (labelToSec(value.format(TIME_S_FORMAT)) < labelToSec(min)) {
        return moment(min, TIME_S_FORMAT).format(format)
      } else if (labelToSec(value.format(TIME_S_FORMAT)) > labelToSec(max)) {
        return moment(max, TIME_S_FORMAT).format(format)
      } else {
        return value.format(format)
      }
    },
    [min, max, format]
  )

  const handleChange = useCallback(
    value => {
      const parsedValue = ampm
        ? moment(getValueInRange(value), format).format(
            withSeconds ? TIME_S_FORMAT : TIME_FORMAT
          )
        : getValueInRange(value)

      onChange(name ? simulateEvent(name, parsedValue) : parsedValue)
      setHasError(false)
    },
    [onChange, name, getValueInRange, ampm, format, withSeconds, setHasError]
  )

  const handleOpenPicker = useCallback(() => {
    setPickerOpen(true)
  }, [])

  const handleClosePicker = useCallback(() => {
    setPickerOpen(false)
  }, [])

  useEffect(
    () => {
      if (!value && min !== '00:00:00') {
        handleChange(parseValue(min))
      }
    },
    // eslint-disable-next-line
    [value, min]
  )

  const views = useMemo(
    () => ['hours', 'minutes', ...(withSeconds ? ['seconds'] : [])],
    [withSeconds]
  )

  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 })

  //position fix for material popup
  useEffect(() => {
    if (ref && ref.current) {
      setTimeout(() => {
        const { top, left, height, width } =
          ref.current?.getBoundingClientRect() || {}
        setAnchorPosition({ top: top + height, left: left + width / 2 })
      }, 0)
    }
  }, [ref])

  const handleBlur = useCallback(
    e => {
      const isValueValid = moment(
        getValueInRange(
          moment(e.target.value, withSeconds ? TIME_S_FORMAT : TIME_FORMAT)
        ),
        withSeconds ? TIME_S_FORMAT : TIME_FORMAT
      ).isValid()

      const valueSeconds = labelToSec(
        moment(
          e.target.value,
          withSeconds ? TIME_S_FORMAT : TIME_FORMAT
        ).format(withSeconds ? TIME_S_FORMAT : TIME_FORMAT)
      )

      if (!isValueValid || valueSeconds > labelToSec(max)) {
        setHasError(true)
        setValue && setValue(max)
      } else if (valueSeconds < labelToSec(min)) {
        setHasError(true)
        setValue && setValue(min)
      } else {
        setHasError(false)
      }
    },
    [getValueInRange, setHasError, withSeconds, setValue, min, max]
  )

  return (
    <FormControlMaterialDateTimeWrap
      ref={ref}
      {...{
        label,
        tooltip,
        tooltipHeader,
        error,
        touched,
        onClickLabel,
        labelRootClassName,
        containerRootClassName,
        labelFontSizeVariant,
        marginBottom,
        labelPosition,
        fullWidth,
        id,
        errorClassNames,
        isOptional
      }}
    >
      <KeyboardTimePicker
        id={id}
        name={name}
        ampm={ampm}
        variant={variant}
        orientation={orientation}
        inputVariant="standard"
        format={format}
        {...(hideIcon && {
          open: isPickerOpen,
          onClick: handleOpenPicker,
          onClose: handleClosePicker
        })}
        onClick={hideIcon && handleOpenPicker}
        value={parseValue(value || min)}
        onChange={handleChange}
        onAccept={handleChange}
        onBlur={handleBlur}
        maskChar="0"
        emptyLabel={min}
        placeholder={min}
        disabled={disabled}
        views={views}
        {...(hasToolbar && {
          ToolbarComponent: Toolbar
        })}
        {...(variant === 'inline' && {
          PopoverProps: {
            classes: {
              root: classes.popoverRoot,
              paper: classNames(classes.popoverPaper, classes.timePicker)
            },
            anchorReference,
            anchorPosition
          }
        })}
        classes={{
          root: classNames(classes.pickerInput, inputRootClassName, {
            [classes.pickerError]: error && touched,
            [classes.disabled]: disabled,
            [classes.pickerFullWidth]: fullWidth
          })
        }}
        KeyboardButtonProps={{
          classes: {
            root: classNames(classes.iconRoot, {
              [classes.iconHidden]: hideIcon
            })
          }
        }}
        InputAdornmentProps={{
          classes: {
            positionEnd: classNames({
              [classes.adornmentHidden]: hideIcon
            })
          }
        }}
        InputProps={{
          ...(hasError &&
            setValue && {
              value: withSeconds ? value : value.slice(0, 5)
            }),
          autoComplete: 'off',
          disableUnderline: true,
          classes: {
            input: classNames(
              classes.textInput,
              {
                [classes.inputSmall]: fontSizeVariant === 'small',
                [classes.inputSmallest]: fontSizeVariant === 'smallest'
              },
              inputTextRootClassName
            )
          }
        }}
        invalidDateMessage={greaterThan24 ? '' : invalidDateMessage}
        inputValue={greaterThan24 ? value : null}
      />
    </FormControlMaterialDateTimeWrap>
  )
}

FormControlTimeDurationPicker.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOptional: PropTypes.bool,
  name: PropTypes.string,
  withSeconds: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelPosition: PropTypes.oneOf(['top', 'left', 'right']),
  tooltip: PropTypes.string,
  tooltipHeader: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  min: PropTypes.string,
  max: PropTypes.string,
  disabled: PropTypes.bool,
  hideIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClickLabel: PropTypes.func,
  fullWidth: PropTypes.bool,
  marginBottom: PropTypes.bool,
  fontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  containerRootClassName: PropTypes.string,
  inputRootClassName: PropTypes.string,
  inputTextRootClassName: PropTypes.string,
  labelRootClassName: PropTypes.string,
  errorClassNames: PropTypes.string,
  ampm: PropTypes.bool,
  displayWithSeconds: PropTypes.bool
}

export default withStyles(theme => ({
  ...styles(theme),
  ...customStyle(theme)
}))(memo(FormControlTimeDurationPicker))
