const sortOptions = (sort, order) => {
  return { sort: sort, order: order }
}

const sortConstants = {
  Tag: sortOptions('tag', 'asc'),
  Group: sortOptions('title', 'asc'),
  Media: sortOptions('updatedAt', 'desc'),
  Device: sortOptions('alias', 'asc'),
  Playlist: sortOptions('updatedAt', 'desc'),
  TutorialVideos: sortOptions('name', 'asc'),
  Template: sortOptions('title', 'asc'),
  Schedule: sortOptions('title', 'asc'),
  User: sortOptions('firstName', 'asc'),
  Room: sortOptions('title', 'asc'),
  ascNameOption: sortOptions('name', 'asc'),
  clients: sortOptions('clientName', 'asc')
}

export default sortConstants
