import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import clientSaga from 'sagas/clientSaga'

const actions = [types.POST_CLIENT_SUCCESS, types.PUT_CLIENT_SUCCESS]

const clientInvalidateCache = () =>
  actions.map(action => takeLatest(action, clientSaga.invalidateClientTags))

export default clientInvalidateCache
