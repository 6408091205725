import { useCallback, useEffect, useRef, useState } from 'react'
import { FormControlInput } from '../../index'
import withStyles from '@material-ui/core/styles/withStyles'

const DELETE_CONTENT_BACKWARD = 'deleteContentBackward'

const styles = () => ({
  container: {
    textTransform: 'uppercase'
  }
})

const DateField = ({
  classes,
  mask = 'dd/mm/yyyy',
  showMaskOnFocus = false,
  showMaskOnHover = false,
  value: inputValue = '',
  onChange = undefined,
  disabled = false,
  readOnly = false,
  onFocusEvent,
  isDatepickerEdit,
  onBlurEvent,
  ...props
}) => {
  const [value, setValue] = useState('')
  const [toggleCursor, setCursor] = useState(false)
  const [positionCursor, setPosCursor] = useState({
    start: 0,
    end: 1
  })
  const [letterObject, setLetterObject] = useState({})
  const [moveCursor, setMoveCursor] = useState({
    start: '',
    end: ''
  })
  const [maskOnFocus, setMaskOnFocus] = useState(false)
  const [statePlaceholder, setStatePlaceholder] = useState('')
  const myRef = useRef(null)

  const newState =
    isDatepickerEdit === props.name
      ? Object.keys(value)?.length > 0
        ? Object.values(value).join('')
        : value
      : inputValue

  useEffect(() => {
    if (newState === mask) {
      myRef.current.setSelectionRange(0, 0)
    }
    // eslint-disable-next-line
  }, [newState])

  useEffect(() => {
    myRef.current.setSelectionRange(positionCursor.start, positionCursor.end)
  }, [positionCursor.start, positionCursor.end, toggleCursor])

  useEffect(() => {
    myRef.current.setSelectionRange(moveCursor.start, moveCursor.end)
  }, [moveCursor.start, moveCursor.end])

  useEffect(() => {
    const value = inputValue ? inputValue : mask
    const valueObject = createObject(value)
    setValue(valueObject)
    if (!showMaskOnFocus || inputValue) setMaskOnFocus(true)
  }, [inputValue, showMaskOnFocus, mask])

  useEffect(() => {
    const letterObject = createObject(mask)
    setLetterObject(letterObject)
    myRef.current.setSelectionRange(0, 1)
  }, [mask])

  const onFocus = useCallback(
    e => {
      if (showMaskOnFocus && !maskOnFocus) {
        setMaskOnFocus(true)
        setStatePlaceholder('')
      }
      onFocusEvent(e)
    },
    [showMaskOnFocus, maskOnFocus, onFocusEvent]
  )

  const findDigitsOrLettersInValue = ({ value, looking }) => {
    const separator = value['3']
    const regex = {
      digits: /[0-9]/g,
      letters: /[mMyYdD]/
    }
    const resultArray = Object.values(value)
      .filter(el => el !== separator)
      .map(el => el.search(regex[looking]))
      .filter(el => el === 0)
    return resultArray.length
  }

  const isCurrValueHaveDigits = useCallback(currValue => {
    const quantityDigits = findDigitsOrLettersInValue({
      value: currValue,
      looking: 'digits'
    })
    const resultIndexLetters = Object.values(currValue).findIndex(el => {
      const regex = /[mMyYdD]/
      const result = el.search(regex)
      return result === 0
    })
    const quantityLetters = findDigitsOrLettersInValue({
      value: currValue,
      looking: 'letters'
    })
    return {
      allDigits: Boolean(quantityDigits === 8),
      indexLetter: resultIndexLetters,
      allLetters: Boolean(quantityLetters === 8)
    }
  }, [])

  const trackingCursorPos = useCallback(
    e => {
      const { allDigits, indexLetter } = isCurrValueHaveDigits(value)
      let { selectionStart } = e.target
      if (allDigits) {
        if (positionCursor.start !== selectionStart) {
          setPosCursor({
            ...positionCursor,
            start: selectionStart,
            end: selectionStart + 1
          })
        }
      } else if (indexLetter || indexLetter === 0) {
        setPosCursor({
          ...positionCursor,
          start: indexLetter,
          end: indexLetter + 1
        })
        setCursor(!toggleCursor)
      } else {
        setCursor(!toggleCursor)
      }
    },
    [value, positionCursor, toggleCursor, isCurrValueHaveDigits]
  )

  const onClick = useCallback(
    e => {
      trackingCursorPos(e)
    },
    [trackingCursorPos]
  )

  const createObject = string => {
    if (typeof string === 'object') {
      string = string.format('DD/MM/YYYY')
    }

    const newObject = {}
    if (string) {
      ;[...string].forEach((el, index) => {
        newObject[index + 1] = el
      })
    }

    return newObject
  }

  const deletingElement = useCallback(
    ({ pos, currentValue }) => {
      const newValue = letterObject[pos]
      let newState = {
        ...currentValue,
        [pos]: newValue
      }
      setValue(newState)
      let newStart = pos - 1
      let newEnd = newStart + 1

      if (newStart === 5 || newStart === 2) {
        const newValue = letterObject[pos - 1]
        const newState = {
          ...currentValue,
          [pos - 1]: newValue
        }
        setValue(newState)
        newStart = newStart - 1
        newEnd = newEnd - 1
      }

      setPosCursor(prevState => {
        return {
          ...prevState,
          start: newStart,
          end: newEnd
        }
      })
      onChange(props.name, Object.values(newState).join(''))
    },
    [letterObject, onChange, props.name]
  )

  const onInput = useCallback(
    e => {
      let {
        target: { selectionStart, selectionEnd, value: curValue },
        nativeEvent: { inputType }
      } = e
      if (inputType === DELETE_CONTENT_BACKWARD) {
        selectionStart += 1
        deletingElement({ pos: selectionStart, currentValue: value })
      } else {
        if (selectionStart === 6 || selectionStart === 3) {
          setPosCursor({
            ...positionCursor,
            start: selectionStart,
            end: selectionEnd
          })
          return
        }
        const valueArray = [...curValue]
        const newPositionStart = selectionStart - 1
        const newValue = valueArray[newPositionStart]
        const reg = /[\d]/g
        const isValidValue = reg.test(newValue)
        let newState
        if (isValidValue && selectionStart < 11) {
          newState = { ...value, [selectionStart]: newValue }
          setValue(newState)
          const newSelectionStart =
            selectionStart === 2 || selectionStart === 5
              ? selectionStart + 1
              : selectionStart
          const newSelectionEnd =
            selectionStart === 2 || selectionStart === 5
              ? selectionEnd + 2
              : selectionEnd + 1
          setPosCursor({
            ...positionCursor,
            start: newSelectionStart,
            end: newSelectionEnd
          })
        } else {
          newState = { ...value }
          setCursor(!toggleCursor)
        }

        onChange(props.name, Object.values(newState).join(''))
      }
    },
    [value, positionCursor, toggleCursor, onChange, props.name, deletingElement]
  )

  const onKeyDown = useCallback(
    e => {
      const {
        key,
        target: { selectionStart }
      } = e
      if (key === 'Backspace' || key === 'Delete') {
        if (selectionStart !== 0) {
          e.preventDefault()
          deletingElement({ pos: selectionStart, currentValue: value })
        } else {
          e.preventDefault()
        }
      } else if (key === 'ArrowRight' || key === 'ArrowLeft') {
        let newStart =
          key === 'ArrowRight' ? selectionStart + 1 : selectionStart - 1
        const newEnd = key === 'ArrowRight' ? selectionStart + 2 : newStart + 1
        setMoveCursor({
          ...moveCursor,
          start: newStart,
          end: newEnd
        })
      }
    },
    [deletingElement, value, moveCursor]
  )

  const onHandleMouseEnter = useCallback(
    e => {
      const { allLetters } = isCurrValueHaveDigits(value)
      if (
        allLetters &&
        showMaskOnHover &&
        statePlaceholder === '' &&
        !maskOnFocus
      ) {
        setStatePlaceholder(mask)
      }
    },
    [
      value,
      showMaskOnHover,
      statePlaceholder,
      mask,
      isCurrValueHaveDigits,
      maskOnFocus
    ]
  )

  const onHandleMouseLeave = useCallback(
    e => {
      const { allLetters } = isCurrValueHaveDigits(value)
      if (allLetters && showMaskOnHover && statePlaceholder && !maskOnFocus) {
        setStatePlaceholder('')
      }
    },
    [
      value,
      showMaskOnHover,
      statePlaceholder,
      maskOnFocus,
      isCurrValueHaveDigits
    ]
  )

  const onHandleBlur = useCallback(
    e => {
      const { allLetters } = isCurrValueHaveDigits(value)
      if (allLetters && showMaskOnFocus && maskOnFocus) {
        setMaskOnFocus(false)
      }
      onBlurEvent(e)
    },
    [value, showMaskOnFocus, maskOnFocus, onBlurEvent, isCurrValueHaveDigits]
  )

  return (
    <FormControlInput
      inputRef={myRef}
      placeholder={!!isDatepickerEdit ? statePlaceholder : ''}
      type="tel"
      onClick={onClick}
      spellCheck="false"
      onChange={onInput}
      onFocus={onFocus}
      value={maskOnFocus ? newState : ''}
      onKeyDown={onKeyDown}
      onMouseEnter={onHandleMouseEnter}
      onMouseLeave={onHandleMouseLeave}
      onBlur={onHandleBlur}
      disabled={disabled}
      readOnly={readOnly}
      formControlInputClass={
        isDatepickerEdit === props.name ? classes.container : ''
      }
      {...props}
    />
  )
}

export default withStyles(styles)(DateField)
