import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  memo,
  useRef
} from 'react'
import WindowedSelect from 'react-windowed-select'
import { components as windowedSelectComponents } from 'react-windowed-select'
import Creatable from 'react-select/creatable'
import ReactSelect, { components } from 'react-select'
import PropTypes from 'prop-types'
import { simulateEvent, sorting } from 'utils'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { InputLabel, makeStyles, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import getOrExecute from 'utils/getOrExecute'
import { _get, _isEmpty, _isFunction } from 'utils/lodash'
import { PRIMARY, SMALL, SMALLEST } from 'constants/fontSizeConstants'
import Tooltip from 'components/Tooltip'
import { zIndexes } from 'constants/stylesConstants'
import { useElementInView } from 'hooks/useElementInView'

const styles = ({
  palette,
  colors,
  type,
  fontSize,
  lineHeight,
  fontWeight
}) => ({
  root: {
    position: 'relative',
    width: '100%',

    '& .react-select__value-container--is-multi': {
      gap: 4,
      paddingRight: 3,

      '& div:last-child': {
        margin: 0,
        padding: 0
      }
    }
  },
  rightLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center'
  },
  topLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  leftLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  bottomLabel: {
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  bootstrapFormLabel: {
    fontSize: fontSize.primary,
    lineHeight: lineHeight.primary,
    fontWeight: fontWeight.normal,
    color: palette[type].formControls.label.color,
    whiteSpace: 'pre',
    transform: 'none'
  },
  alignLabel: {
    alignSelf: 'flex-start'
  },
  topLabelMargin: {
    marginBottom: 0
  },
  bottomLabelMargin: {
    marginTop: '7px'
  },
  copyBtn: {
    position: 'absolute',
    right: '55px',
    bottom: '8px',
    cursor: 'pointer',
    color: '#afb7c7'
  },
  copyBtnRightOffset: {
    right: '35px'
  },
  copyBtnFocused: {
    color: 'rgb(95,95,95)'
  },
  copyBtnHoveredWithFocus: {
    '&:hover': {
      color: 'rgb(65,65,65)'
    },
    '&:active': {
      color: 'rgb(21,21,21)'
    }
  },
  copyBtnHoveredWithoutFocus: {
    '&:hover': {
      color: 'rgb(153, 153, 153)'
    },
    '&:active': {
      color: 'rgb(89,89,89)'
    }
  },
  errorText: {
    color: colors.error,
    position: 'absolute',
    fontSize: 10,
    left: 5,
    whiteSpace: 'nowrap'
  },
  bootstrapFormLabelFocus: {
    color: `${palette[type].formControls.label.color} !important`
  },
  labelLink: {
    display: 'unset',
    position: 'unset !important',
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: colors.highlight,
    textUnderlineOffset: '2px',
    '&:hover': {
      cursor: 'pointer',
      textDecorationStyle: 'solid'
    }
  },
  label: {
    position: 'unset !important'
  },
  labelSmall: {
    fontSize: `${fontSize.small}px !important`
  },
  labelSmallest: {
    fontSize: `${fontSize.smallest}px !important`
  }
})

const getStyles = (
  {
    palette,
    colors,
    type,
    typography,
    shapes,
    fontSize,
    lineHeight,
    fontWeight,
    formControls,
    spacing
  },
  isMulti,
  styles,
  showError,
  fontSizeVariant,
  menuWidth,
  maxHeight,
  height,
  maxWidth,
  isGroupSelectable
) => {
  const {
    container,
    control,
    input,
    placeholder,
    menu,
    menuPortal,
    noOptionsMessage,
    option,
    multiValue,
    indicatorsContainer,
    loadingMessage,
    multiValueLabel,
    multiValueRemove,
    singleValue,
    onFocus,
    valueContainer
  } = styles

  const fontSizeMap = {
    [PRIMARY]: fontSize.primary,
    [SMALL]: fontSize.small,
    [SMALLEST]: fontSize.smallest
  }

  return {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      position: 'relative',
      ...(maxWidth && { maxWidth }),
      ...getOrExecute(container, state)
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: palette[type].formControls.input.background,
      border: `1px solid ${palette[type].formControls.input.border}`,
      borderRadius: 4,
      boxSizing: 'border-box',
      padding: isMulti ? `5px ${spacing(1)}px` : `0 ${spacing(1)}px`,
      ...(showError ? { borderBottom: `1px solid ${colors.error}` } : {}),
      height: 'inherit',
      minHeight: shapes.height.primary,
      zIndex: 0,
      ...getOrExecute(control, state),

      '&:hover': {
        backgroundColor: palette[type].formControls.input.background,
        ...getOrExecute(_get(control, '&:hover', {}), state)
      },

      '& > div': {
        padding: 0,
        ...getOrExecute(_get(control, '& > div', {}), state)
      },

      ...(state.isFocused && {
        border: '1px solid #80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        ...onFocus
      }),

      ...(state.isDisabled && {
        cursor: 'default',
        backgroundColor: palette[type].formControls.disabled.background
      })
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: fontSizeMap[fontSizeVariant],
      backgroundColor: 'transparent',
      color: palette[type].formControls.input.color,
      fontFamily: typography.fontFamily,
      '& input': {
        fontFamily: 'inherit'
      },
      ...getOrExecute(input, state)
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: fontSizeMap[fontSizeVariant],
      color: palette[type].formControls.input.color,
      fontFamily: typography.fontFamily,
      ...formControls.placeholder,
      ...getOrExecute(placeholder, state)
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: zIndexes.selectMenu,
      background: palette[type].formControls.select.background,
      ...(menuWidth ? { width: menuWidth } : {}),
      ...getOrExecute(menu, state)
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: zIndexes.selectMenu,
      ...getOrExecute(menuPortal, state)
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontFamily: typography.fontFamily,
      color: palette[type].formControls.input.color,
      ...getOrExecute(noOptionsMessage, state)
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: _get(state, 'data.isGroupLabel')
        ? '75%'
        : fontSizeMap[fontSizeVariant],
      fontWeight: _get(state, 'data.isGroupLabel') ? 500 : fontWeight.normal,
      textTransform: _get(state, 'data.isGroupLabel') ? 'uppercase' : 'none',
      label: _get(state, 'data.isGroupLabel') ? 'group' : '',
      color: _get(state, 'data.isGroupLabel')
        ? '#999'
        : palette[type].formControls.input.color,
      fontFamily: typography.fontFamily,
      background: state.isFocused
        ? palette[type].formControls.select.border
        : palette[type].formControls.select.background,
      cursor: _get(state, 'data.isGroupLabel') ? 'default' : 'pointer',
      padding: spacing(1),
      ...getOrExecute(option, state),
      ...(_get(state, 'data.isGroupLabel') && {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '25px',
        padding: '5px 12px 0 12px'
      }),
      zIndex: 10,
      '&:hover': {
        background: state.isFocused
          ? palette[type].formControls.select.border
          : '',
        ...getOrExecute(_get(option, '&:hover', {}), state)
      },
      overflowWrap: 'break-word',

      ...(state.isDisabled && {
        background: _get(state, 'data.isGroupLabel')
          ? palette[type].formControls.select.background
          : palette[type].formControls.disabled.background,
        color: _get(state, 'data.isGroupLabel')
          ? '#999'
          : palette[type].formControls.disabled.color
      }),
      ...(isGroupSelectable && {
        padding: spacing(1, 3),
        background: state.isFocused
          ? 'rgb(0,0,0,0)'
          : palette[type].formControls.select.background
      }),
      ...(_get(state, 'data.singleLineOption') && {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      })
    }),
    multiValue: (provided, state) => ({
      ...provided,
      display: 'inline-flex',
      alignItems: 'center',
      height: 20,
      borderRadius: 3,
      padding: '0 4px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#3cd480',
      background: 'rgba(60, 212, 128, 0.25)',
      margin: 0,
      ...getOrExecute(multiValue, state)
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      ...getOrExecute(valueContainer, state),
      ...(maxHeight || height
        ? {
            overflow: 'auto',
            height,
            maxHeight,
            alignContent: 'baseline',
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: palette[type].scrollbar.background,
              borderRadius: '5px'
            }
          }
        : {}),
      '& > div': {
        marginLeft: 0,
        ...getOrExecute(_get(valueContainer, '& > div', {}), state)
      }
    }),
    clearIndicator: provided => {
      return {
        ...provided,
        '& i': {
          lineHeight: 'normal'
        }
      }
    },
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      ...getOrExecute(indicatorsContainer, state),

      '& > div': {
        padding: 0,
        ...getOrExecute(_get(indicatorsContainer, '& > div', {}), state)
      }
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontFamily: typography.fontFamily,
      ...getOrExecute(loadingMessage, state)
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontSize: fontSizeMap[fontSizeVariant],
      fontWeight: fontWeight.bold,
      fontFamily: typography.fontFamily,
      marginRight: 5,
      color: '#3cd480',
      userSelect: 'none',
      ...getOrExecute(multiValueLabel, state)
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        backgroundColor: '#3cd480',
        'svg path': {
          fill: '#fff'
        }
      },
      svg: {
        cursor: 'pointer'
      },
      'svg path': {
        fill: '#3cd480'
      },

      ...getOrExecute(multiValueRemove, state)
    }),
    singleValue: (provided, state) => ({
      '& > input, & > div': {
        ...provided,
        border: 'unset',
        background: 'transparent',
        fontFamily: typography.fontFamily,
        color: palette[type].formControls.input.color,
        fontSize: fontSizeMap[fontSizeVariant],
        fontWeight: fontWeight.normal,
        zIndex: -1,
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        ...getOrExecute(singleValue, state),

        '&:focus': {
          zIndex: 1
        }
      }
    }),
    groupHeading: provided => ({
      ...provided,
      ...(isGroupSelectable && {
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '0',
        padding: '0px',
        '& div': {
          padding: spacing(1),
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: palette[type].formControls.select.border
          }
        }
      })
    }),
    group: provided => ({
      ...provided,
      ...(isGroupSelectable && { padding: 0 })
    })
  }
}

const SingleValue = ({ classes, children, data, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {typeof data.label === 'string' ? (
        <input value={data.label} readOnly size={data.label.length + 2} />
      ) : (
        <div>{data.label}</div>
      )}
    </components.SingleValue>
  )
}

const iconStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  icon: {
    fontSize: 16,
    marginRight: 8
  }
})

const SingleIconValue = ({ classes, children, data, ...props }) => {
  const iconClasses = iconStyles()
  return (
    <components.SingleValue {...props}>
      <div className={iconClasses.root}>
        <i className={classNames(iconClasses.icon, data.icon)}></i>
        {data.label}
      </div>
    </components.SingleValue>
  )
}

const Option = props => {
  const isLastOption =
    props.options[props.options.length - 1].value === props.data.value

  if (isLastOption) {
    props.setTargetRendered(true)

    return (
      <div ref={props.targetRef}>
        <components.Option {...props} />
      </div>
    )
  }

  return (
    <div>
      <components.Option {...props} />
    </div>
  )
}

const IconOption = ({ children, data, ...props }) => {
  const classes = iconStyles()

  return (
    <components.Option {...props}>
      <div className={classes.root}>
        <i className={classNames(classes.icon, data.icon)}></i>
        {data.label}
      </div>
    </components.Option>
  )
}

const GroupComponent = props => {
  return (
    <div
      onClick={e => {
        if (!e.target?.id.startsWith('react-select')) {
          const data = props.data
          const values = props.getValue()
          props.setValue([...values, data], 'set-value', data)
        }
      }}
    >
      <components.Group {...props} />
    </div>
  )
}

const customWindowedSelectComponents = {
  ClearIndicator: props => {
    return (
      <windowedSelectComponents.ClearIndicator {...props}>
        <i className="fa-solid fa-xmark" />
      </windowedSelectComponents.ClearIndicator>
    )
  }
}

const SelectComponent = ({
  isCreatable,
  withWindowedSelect,
  formatCreateLabel,
  childRef,
  ...props
}) => {
  let windowedSelectConfig = {
    ...props
  }
  if (withWindowedSelect && props.isClearable) {
    windowedSelectConfig = {
      ...windowedSelectConfig,
      components: {
        ...windowedSelectConfig.components,
        ...customWindowedSelectComponents
      }
    }
  }

  const defaultCreateLabelFormatter = value => (
    <span style={{ fontSize: '13px' }}>{`Search for "${value}"`}</span>
  )

  return isCreatable ? (
    <Creatable
      {...props}
      ref={childRef}
      allowCreateWhileLoading
      createOptionPosition="first"
      formatCreateLabel={formatCreateLabel || defaultCreateLabelFormatter}
    />
  ) : withWindowedSelect ? (
    <WindowedSelect ref={childRef} {...windowedSelectConfig} />
  ) : (
    <ReactSelect ref={childRef} {...props} />
  )
}

const FormControlChips = ({
  t,
  classes,
  label = '',
  isOptional = false,
  marginBottom = 16,
  theme,
  options = [],
  name = 'tag',
  noOptionsMessage,
  placeholder = '',
  values = [],
  handleChange = f => f,
  isSearchable = true,
  isLoading = false,
  customClass = '',
  isMulti = true,
  disabled = false,
  formControlLabelClass = '',
  formControlContainerClass = '',
  handleInputChange = f => f,
  error = '',
  touched = false,
  fontSizeVariant = 'small',
  labelFontSizeVariant,
  tooltip = '',
  tooltipHeaderText = '',
  handleMenuScrollToBottom = f => f,
  handleBlur: onBlur = f => f,
  isClearable = false,
  components = {},
  formatOptionLabel,
  styles = {},
  createdValue = {},
  createNewValue = null,
  clearCreatedValue = f => f,
  hasDynamicChipsCreation = true,
  isCreatable,
  returnValues = false,
  withPortal = false,
  onFocus = f => f,
  isSort = true,
  labelPosition,
  errorClassNames,
  isOptionDisabled,
  withWindowedSelect = true,
  filterOption,
  readOnly,
  removeValueOnBlur = true,
  menuPosition = 'absolute',
  menuPlacement = 'auto',
  withIcons = false,
  menuIsOpen: menuOpen,
  isInputEditable = false,
  isReactSelectCreatable = false,
  menuWidth,
  onMenuClose,
  keepValueOnBlur = false,
  keepValueOnSelect = false,
  maxHeight,
  maxWidth,
  height,
  maxMenuHeight = 300,
  formatCreateLabel,
  isGroupSelectable = false,
  useCustomMenuScrollLogic = true,
  hasStaticOptions = false,
  labelIcon,
  showNoOptionsMessageOnError = false
}) => {
  const [inputValue, setInputValue] = useState('')
  const [valueToCreate, setValueToCreate] = useState(null)
  const [isFocused, setIsFocused] = useState(false)

  let inputRef = useRef(null)
  const targetRef = useRef(null)
  const [targetRendered, setTargetRendered] = useState(false)

  const [isInView] = useElementInView({
    targetRef,
    targetRendered,
    hasStaticOptions
  })

  const customStyles = useMemo(
    () =>
      getStyles(
        theme,
        isMulti,
        styles,
        error && touched,
        fontSizeVariant,
        menuWidth,
        maxHeight,
        height,
        maxWidth,
        isGroupSelectable
      ),
    [
      isMulti,
      theme,
      styles,
      error,
      touched,
      fontSizeVariant,
      menuWidth,
      maxHeight,
      height,
      maxWidth,
      isGroupSelectable
    ]
  )

  const computedValue = useMemo(() => {
    if (
      typeof values === 'number' ||
      typeof values === 'boolean' ||
      (typeof values === 'string' && values.length > 0)
    ) {
      return (
        options.find(searchValue => searchValue.value === values) || {
          value: values,
          label: values,
          alias: values,
          __isNew__: true
        } ||
        []
      )
    }

    if (Array.isArray(values) && returnValues) {
      return values
        .map(value => options.find(option => option.value === value)) //to keep selection order
        .filter(Boolean)
    }

    if (values?.value) {
      const selectedValue = options.find(
        option => option.value === values.value
      )
      if (selectedValue) return selectedValue
    }

    if (isReactSelectCreatable && !values && !!inputValue) {
      return {
        value: inputValue,
        label: inputValue
      }
    }

    return values
  }, [options, returnValues, values, isReactSelectCreatable, inputValue])

  const allowCreateValue = useMemo(
    () => hasDynamicChipsCreation && _isFunction(createNewValue),
    [hasDynamicChipsCreation, createNewValue]
  )

  const onChangeHandler = useCallback(
    (newValue, ...options) => {
      if (isMulti) {
        handleChange({
          target: { name, value: newValue || [], ...newValue, options }
        })
      } else {
        if (newValue && newValue.__isNew__) {
          handleChange({
            target: { name, ...newValue, alias: newValue.value }
          })
        } else {
          handleChange({
            target: { name, ...newValue }
          })
        }
      }
    },
    [handleChange, isMulti, name]
  )

  const onBlurHandler = useCallback(
    event => {
      setTargetRendered(false)
      setIsFocused(false)
      if (isReactSelectCreatable && !!event.target.value) {
        handleChange(simulateEvent(name, event.target.value))
      } else {
        onBlur(
          simulateEvent(name, removeValueOnBlur ? null : event.target.value)
        )
        isInputEditable && !isMulti && setInputValue('')
      }
    },
    [
      onBlur,
      handleChange,
      name,
      removeValueOnBlur,
      isInputEditable,
      isMulti,
      isReactSelectCreatable,
      setTargetRendered
    ]
  )

  const onInputChangeHandler = useCallback(
    (value, data) => {
      setTargetRendered(false)

      if (isInputEditable && !isMulti) {
        if (data.action === 'set-value') {
          inputRef.current?.select?.blur()
          setInputValue(value)
        } else if (!['input-blur', 'menu-close'].includes(data.action)) {
          setInputValue(value)
        }
        handleInputChange(value, data)
      } else {
        if (
          keepValueOnBlur &&
          ['input-blur', 'menu-close'].includes(data.action)
        ) {
          return
        }
        if (keepValueOnSelect && data.action === 'set-value') {
          return
        }
        setInputValue(value)
        handleInputChange(value, data)
      }
    },
    [
      handleInputChange,
      isInputEditable,
      isMulti,
      keepValueOnBlur,
      keepValueOnSelect,
      setTargetRendered
    ]
  )

  const onKeyDownHandler = useCallback(
    e => {
      if (allowCreateValue && e.key === 'Enter' && isMulti) {
        if (
          (_isEmpty(inputValue) && _isEmpty(options)) ||
          values.find(({ label }) => label === inputValue)
        ) {
          e.preventDefault()
        } else {
          const item = options
            .filter(opt => !values.find(({ label }) => label === opt.label))
            .find(({ label }) =>
              label.toLowerCase().includes(inputValue.toLowerCase())
            )
          if (!item && !valueToCreate) {
            e.preventDefault()
            setValueToCreate(inputValue)
            createNewValue(inputValue)
          }
        }
      } else if (e.key === 'Enter' && name === 'schedule-time') {
        // Permits non-option value inputs for create-schedule page time pickers
        e.preventDefault()
        setValueToCreate(inputValue)
        createNewValue(inputValue)
      }
    },
    [
      createNewValue,
      inputValue,
      isMulti,
      options,
      name,
      values,
      valueToCreate,
      allowCreateValue
    ]
  )

  const noOptionsMessageHandler = useCallback(() => {
    // Displays current input as no-options-message for create-schedule page time pickers
    if (name === 'schedule-time') {
      return inputValue
    }
    if (valueToCreate && allowCreateValue) {
      return t('Loading...')
    }
    if (typeof noOptionsMessage === 'function') {
      return noOptionsMessage(inputValue)
    }
    if (error && !showNoOptionsMessageOnError) return null
    return noOptionsMessage || t('No Options')
  }, [
    t,
    name,
    inputValue,
    noOptionsMessage,
    valueToCreate,
    error,
    allowCreateValue,
    showNoOptionsMessageOnError
  ])

  useEffect(() => {
    const { data, error } = createdValue
    if (data && data.label === valueToCreate) {
      handleChange({
        target: {
          name,
          value: [...values, data]
        }
      })
      setInputValue('')
      setValueToCreate(null)
      clearCreatedValue()
    } else if (error) {
      setValueToCreate(null)
      clearCreatedValue()
    }
    // eslint-disable-next-line
  }, [createdValue])

  const menuIsOpen = useMemo(() => (readOnly === true ? !readOnly : menuOpen), [
    readOnly,
    menuOpen
  ])

  const editableFilterOption = useCallback(
    (option, searchText) => {
      if (searchText === computedValue.label) {
        return true
      }
      return (
        String(option.value).toLowerCase().includes(searchText.toLowerCase()) ||
        (typeof option.label === 'string' &&
          option.label.toLowerCase().includes(searchText.toLowerCase()))
      )
    },
    [computedValue]
  )

  const handleFocus = useCallback(
    e => {
      setTargetRendered(false)
      setIsFocused(true)
      if (!isMulti && isInputEditable) {
        setInputValue(computedValue.label)
      }
      onFocus(e)
    },
    [onFocus, isInputEditable, isMulti, computedValue, setTargetRendered]
  )

  useEffect(() => {
    if (isInView && useCustomMenuScrollLogic) {
      handleMenuScrollToBottom()
    }
    // eslint-disable-next-line
  }, [isInView])

  return (
    <div
      onClick={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
      style={{ marginBottom }}
      className={classNames(classes.root, formControlContainerClass, {
        [classes.leftLabel]: labelPosition === 'left',
        [classes.topLabel]: labelPosition === 'top',
        [classes.bottomLabel]: labelPosition === 'bottom',
        [classes.rightLabel]: labelPosition === 'right'
      })}
    >
      {label && (
        <Tooltip
          arrow
          withHeader={!!tooltipHeaderText}
          headerText={tooltipHeaderText}
          title={tooltip}
          disableHoverListener={!tooltip}
          placement="top"
        >
          <InputLabel
            shrink
            className={classNames(
              classes.bootstrapFormLabel,
              formControlLabelClass,
              {
                [classes.alignLabel]:
                  labelPosition === 'top' || labelPosition === 'bottom',
                [classes.topLabelMargin]: labelPosition === 'top',
                [classes.bottomLabelMargin]: labelPosition === 'bottom',
                [classes.labelSmall]: labelFontSizeVariant === 'small',
                [classes.labelSmallest]: labelFontSizeVariant === 'smallest'
              }
            )}
            classes={{
              focused: classes.bootstrapFormLabelFocus,
              root: tooltip ? classes.labelLink : classes.label
            }}
          >
            {label} {isOptional && <i>({t('optional')})</i>}
            {!!labelIcon && labelIcon}
          </InputLabel>
        </Tooltip>
      )}

      <SelectComponent
        isCreatable={isCreatable}
        childRef={ref => (inputRef.current = ref)}
        styles={customStyles}
        isSearchable={isSearchable && !readOnly}
        isLoading={isLoading}
        isClearable={isClearable && !readOnly}
        className={customClass}
        classNamePrefix="react-select"
        noOptionsMessage={noOptionsMessageHandler}
        placeholder={placeholder}
        isMulti={isMulti}
        options={isSort ? sorting(options, 'label') : options}
        formatOptionLabel={formatOptionLabel}
        backspaceRemovesValue={false}
        components={{
          IndicatorSeparator: null,
          SingleValue: withIcons ? SingleIconValue : SingleValue,
          ...(withIcons ? { Option: IconOption } : {}),
          ...components,
          ...(isGroupSelectable && { Group: GroupComponent }),
          Option: params => Option({ ...params, targetRef, setTargetRendered })
        }}
        {...(!useCustomMenuScrollLogic && {
          onMenuScrollToBottom: handleMenuScrollToBottom
        })}
        value={computedValue}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        menuPlacement={menuPlacement}
        menuShouldScrollIntoView={false}
        inputValue={inputValue}
        onInputChange={onInputChangeHandler}
        onKeyDown={onKeyDownHandler}
        isDisabled={disabled}
        menuPortalTarget={withPortal ? document.body : undefined}
        withWindowedSelect={withWindowedSelect}
        filterOption={
          (isInputEditable && !isMulti && editableFilterOption) || filterOption
        }
        {...(isOptionDisabled && { isOptionDisabled })}
        menuIsOpen={menuIsOpen}
        menuPosition={menuPosition}
        onFocus={handleFocus}
        {...(onMenuClose && { onMenuClose })}
        maxMenuHeight={maxMenuHeight}
        formatCreateLabel={formatCreateLabel}
        isFocused={isFocused}
      />
      {error && touched && (
        <Typography
          className={classNames(classes.errorText, errorClassNames)}
          style={{ top: !errorClassNames && (label ? 54 : 36) }}
        >
          {t(error)}
        </Typography>
      )}
    </div>
  )
}

FormControlChips.propTypes = {
  classes: PropTypes.object,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  isOptional: PropTypes.bool,
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  options: PropTypes.array,
  noOptionsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  placeholder: PropTypes.string,
  values: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  handleInputChange: PropTypes.func,
  fontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  error: PropTypes.string,
  isSearchable: PropTypes.bool,
  touched: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array
  ]),
  isSort: PropTypes.bool,
  handleMenuScrollToBottom: PropTypes.func,
  handleBlur: PropTypes.func,
  isClearable: PropTypes.bool,
  components: PropTypes.object,
  styles: PropTypes.object,
  createdValue: PropTypes.object,
  clearCreatedValue: PropTypes.func,
  hasDynamicChipsCreation: PropTypes.bool,
  labelPosition: PropTypes.string,
  isOptionDisabled: PropTypes.func,
  tooltip: PropTypes.string,
  tooltipHeaderText: PropTypes.string,
  filterOption: PropTypes.func,
  removeValueOnBlur: PropTypes.bool,
  keepValueOnBlur: PropTypes.bool,
  keepValueOnSelect: PropTypes.bool,
  maxMenuHeight: PropTypes.number
}

export default withTranslation('translations')(
  withStyles(styles, { withTheme: true })(memo(FormControlChips))
)
