import React from 'react'
import {
  Typography,
  useMediaQuery,
  withStyles,
  withTheme
} from '@material-ui/core'
import classNames from 'classnames'

import AccountModal from './AccountModal'
import LogoImage from './LogoImage'
import useWhiteLabel from 'hooks/useWhiteLabel'

function styles({ palette, type, mixins, spacing }) {
  return {
    systemView: {
      backgroundColor: '#121212'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginBottom: 20,
      maxWidth: '800px'
    },
    containerSystemUser: {
      maxWidth: spacing(71),
      padding: spacing(0, 8)
    },
    containerSm: {
      maxWidth: '100%',
      padding: spacing(0, 2)
    },
    logoImage: {
      width: 120,
      marginBottom: spacing(3)
    },
    logoImageSystemUser: {
      ...mixins.loginPageLogo,
      marginBottom: spacing(13)
    },
    logoImageXs: {
      marginBottom: spacing(7)
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: spacing(5),
      color: palette[type].pages.singIn.color,
      fontSize: '38px'
    },
    formTitleXs: {
      marginBottom: spacing(3)
    },
    header: {
      textAlign: 'center'
    },
    headerRoot: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}

const SignInWrapper = ({
  classes,
  isSystemUser,
  title,
  theme,
  children,
  isManually = false,
  isSigninPage = false
}) => {
  const { darkHeaderLogo, headerLogo } = useWhiteLabel()
  const isLightLogo = theme.type === 'dark' && darkHeaderLogo
  const matchesSm = useMediaQuery('(max-width:800px)')
  const matchesXs = useMediaQuery('(max-width:576px)')

  return (
    <AccountModal
      hideDynamicImages={isSystemUser}
      rootClassName={classNames({
        [classes.systemView]: isSystemUser
      })}
      isSigninPage={isSigninPage}
      isSystemUser={isSystemUser}
      isManually={isManually}
    >
      <div
        className={classNames({
          [classes.container]: !isSystemUser && !isManually,
          [classes.containerSystemUser]: isSystemUser || isManually,
          [classes.containerSm]: matchesSm
        })}
      >
        <header
          className={classNames(classes.headerRoot, {
            [classes.header]: !isSystemUser && !isManually
          })}
        >
          <LogoImage
            isLightLogo={isLightLogo}
            darkHeaderLogo={darkHeaderLogo}
            headerLogo={headerLogo}
            logoImageClassName={classNames({
              [classes.logoImage]: !isSystemUser,
              [classes.logoImageSystemUser]: isSystemUser || isManually,
              [classes.logoImageXs]: matchesXs
            })}
          />
          <Typography
            className={classNames(classes.formTitle, {
              [classes.formTitleXs]: matchesXs
            })}
            variant="h2"
            gutterBottom
          >
            {title}
          </Typography>
        </header>
        {children}
      </div>
    </AccountModal>
  )
}

export default withStyles(styles)(withTheme(SignInWrapper))
