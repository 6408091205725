import { useCallback, useEffect, useRef, useState } from 'react'

import { useLazyGetPlaylistQuery } from 'api/playlistsApi'
import { _get } from 'utils/lodash'
import { getPlaylistThumbnail } from 'utils/playlistUtils'
import { playlistConstants } from 'constants/index'
import useTimeout from 'hooks/useTimeout'

export default function usePlaylistThumbnails({
  playlist,
  requestDelay = 800,
  interval = 4000,
  maxSlides = 10
}) {
  const [getPlaylist, { data, isFetching }] = useLazyGetPlaylistQuery()

  const [setRequestTimeout, clearRequestTimeout] = useTimeout()
  const intervalRef = useRef(null)
  const [position, setPosition] = useState(-1)

  const startInterval = useCallback(
    (mediaList = []) => {
      intervalRef.current = setInterval(
        () =>
          setPosition(prevState => {
            const nextPosition = prevState + 1

            if (nextPosition < maxSlides && nextPosition < mediaList.length) {
              return nextPosition
            } else {
              return 0
            }
          }),
        interval
      )
    },
    [interval, maxSlides]
  )

  const startSlideshow = useCallback(() => {
    if (!data) {
      setRequestTimeout(() => getPlaylist(playlist.id), requestDelay)
    } else if (data.media?.length && data.media.length > 1) {
      startInterval(data.media)
    }
  }, [
    data,
    getPlaylist,
    playlist,
    requestDelay,
    setRequestTimeout,
    startInterval
  ])

  useEffect(() => {
    if (data?.media?.length && data.media.length > 1) {
      startInterval(data.media)
    }
    // eslint-disable-next-line
  }, [data])

  const stopSlideshow = useCallback(() => {
    clearRequestTimeout()
    clearInterval(intervalRef.current)
    setPosition(-1)
  }, [clearRequestTimeout])

  const playlistTypeInfo =
    playlistConstants.playlistTypes[playlist.playlistType] || {}

  const defaultThumbnailData = {
    thumbnail: getPlaylistThumbnail(playlist),
    thumbnailColor: playlistTypeInfo.color,
    thumbnailIcon: playlistTypeInfo.iconHelperClass
  }

  const media = _get(data, `media[${position}]`)

  return {
    startSlideshow,
    stopSlideshow,
    loading: isFetching,
    thumbnailData:
      position !== -1 && media
        ? {
            thumbnail: media.thumbUrl || defaultThumbnailData.thumbnail,
            thumbnailColor:
              media.feature?.color || defaultThumbnailData.thumbnailColor,
            thumbnailIcon:
              media.feature?.icon || defaultThumbnailData.thumbnailIcon
          }
        : defaultThumbnailData
  }
}
