import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import SideModal from 'components/Modal/SideModal'
import { BlueButton, WhiteButton } from 'components/Buttons'
import { FormControlInput } from 'components/Form'
import { ipRegExp } from 'constants/regExp'
import {
  useDeleteSupportTokenAllowedIpMutation,
  useGetSupportTokenAllowedIpsQuery,
  usePostSupportTokenAllowedIpMutation,
  usePutSupportTokenAllowedIpMutation
} from 'api/supportToken'
import IPWhitelistController from 'components/IPWhitelistController'
import useSnackbar from 'hooks/useSnackbar'
import { requiredField } from 'constants/validationMessages'

const useStyles = makeStyles({
  actions: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: '10px'
  },
  addBtn: {
    marginTop: 7,
    width: 62
  },
  controlInput: {
    flexGrow: 2,
    marginRight: 10
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 30px',
    height: 'inherit'
  },
  wrapperControlElements: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const IPWhitelist = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  // API DEPRECATION

  const [fetchedPermissions] = useSelector(
    ({
      role: {
        permissionById: { response: permissionsResponse }
      }
    }) => {
      const fetchedPermissions =
        permissionsResponse && Object.keys(permissionsResponse).length > 0
          ? permissionsResponse?.filter(({ checkIp }) => checkIp)
          : null
      return [fetchedPermissions]
    }
  )

  const [postItem, postResult] = usePostSupportTokenAllowedIpMutation()
  const {
    data: { data: whitelistIPAddress = [] } = {},
    isFetching
  } = useGetSupportTokenAllowedIpsQuery({ limit: 9999 })

  const [updateItem, updateResult] = usePutSupportTokenAllowedIpMutation()
  const [deleteItem, deleteResult] = useDeleteSupportTokenAllowedIpMutation()
  const { showSnackbar } = useSnackbar()
  const [whitelistIPList, setWhitelistIPList] = useState([])

  useEffect(() => {
    if (isFetching) return
    setWhitelistIPList(whitelistIPAddress)
  }, [isFetching, whitelistIPAddress])

  const initialValues = useRef({
    ip: '',
    title: ''
  })

  const form = useFormik({
    initialValues: initialValues.current,
    validationSchema: Yup.object().shape({
      ip: Yup.string()
        .restrictedCharacters()
        .matches(ipRegExp, 'Please enter valid IP Address')
        .required(requiredField),
      title: Yup.string().restrictedCharacters().required(requiredField)
    }),
    onSubmit: ({ ip, title }) => {
      postItem({
        permissionId: fetchedPermissions?.[0].id,
        title,
        ip
      })
    }
  })

  const {
    values,
    errors,
    touched,
    setValues,
    setTouched,
    handleSubmit,
    handleChange,
    setErrors
  } = form

  const handleReset = useCallback(() => {
    setValues(initialValues.current)
  }, [setValues])

  useEffect(() => {
    const handleResult = (result, operation) => {
      const message = t('IP o Successfully', { operation })
      if (result?.status !== 'fulfilled') return
      if (result?.isSuccess) {
        showSnackbar(message, 'success')
        handleReset()
        setTouched({ ip: false, title: false })
      } else {
        showSnackbar(t('Oops.. Something went wrong'), 'error')
      }
      result.reset()
    }

    handleResult(postResult, 'Added')
    handleResult(deleteResult, 'Removed')
    handleResult(updateResult, 'Updated')
    // eslint-disable-next-line
  }, [postResult, updateResult, deleteResult])

  useEffect(() => {
    if (postResult?.error?.errorFields) {
      let err = {}
      postResult.error.errorFields.forEach(error => {
        err[error.name] = error.value[0]
      })

      setErrors(err)
    }
    // eslint-disable-next-line
  }, [postResult?.error?.errorFields])

  return (
    <SideModal
      width="40%"
      title={t('IP Whitelist | Support Token')}
      closeLink={'/system/roles-permissions'}
      footerLayout={
        <div className={classes.actions}>
          <WhiteButton
            variant="danger"
            onClick={handleReset}
            iconClassName="fa-regular fa-circle-arrow-left"
          >
            {t('Reset')}
          </WhiteButton>
        </div>
      }
    >
      <div className={classes.wrapper}>
        <div className={classes.wrapperControlElements}>
          <FormControlInput
            label={t('IP Address')}
            formControlContainerClass={classes.controlInput}
            handleChange={handleChange}
            value={values.ip}
            error={errors.ip}
            touched={touched.ip}
            name="ip"
          />
          <FormControlInput
            label={t('Name')}
            formControlContainerClass={classes.controlInput}
            handleChange={handleChange}
            value={values.title}
            error={errors.title}
            touched={touched.title}
            name="title"
          />
          <BlueButton
            fullWidth
            className={classes.addBtn}
            onClick={handleSubmit}
            disabled={false}
            iconClassName="fa-regular fa-circle-plus"
          >
            {t('Add')}
          </BlueButton>
        </div>

        <IPWhitelistController
          values={whitelistIPList}
          deleteItem={deleteItem}
          updateItem={updateItem}
          updateResult={updateResult}
          t={t}
        />
      </div>
    </SideModal>
  )
}

export default IPWhitelist
