import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'

import Card from 'components/Card/Card'
import MoreInfoRow from 'components/Card/MoreInfoRow'
import { truncateLength } from 'components/Card/consts'
import { getDuration } from 'utils/mediaUtils'
import {
  ActiveStatus,
  InactiveStatus,
  PendingStatus
} from 'components/StatusText'
import { DateTimeView } from 'components/TableLibrary'
import { checkData } from 'utils/tableUtils'
import { playlistConstants } from 'constants/index'
import { TextWithTooltip } from 'components/Typography'
import { useGetPlaylistQuery } from 'api/playlistsApi'

const styles = ({ palette, type, typography }) => ({
  cardRoot: {
    padding: 0,
    borderRadius: '7px',
    width: '100%'
  },
  moreInfoCardHeader: {
    padding: '0 20px',
    marginBottom: 0,
    borderBottom: `solid 1px ${palette[type].deviceCard.border}`,
    backgroundColor: palette[type].deviceCard.header.background,
    borderRadius: '8px 8px 0 0'
  },
  moreInfoCardHeaderWrap: {
    width: '100%',
    display: 'flex'
  },
  moreInfoCardHeaderText: {
    ...typography.darkAccent[type],
    lineHeight: '45px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px'
  },
  dateTimeViewRoot: {
    textAlign: 'right'
  }
})

const PlaylistMoreInfoCard = ({ t, item, classes }) => {
  const {
    data: playlistData = {},
    isFetching,
    isUninitialized
  } = useGetPlaylistQuery(item.id)

  const data = useMemo(() => {
    const playlistTypeInfo =
      playlistConstants.playlistTypes[playlistData.playlistType] || {}

    return [
      {
        label: t('Type'),
        value: playlistTypeInfo.title || ''
      },
      {
        label: t('Duration'),
        value: getDuration(playlistData, true) || 'N/A'
      },
      {
        label: t('Media Count'),
        value: checkData(playlistData.noOfFiles) || 0
      },
      {
        label: t('Status'),
        isCustomValue: true,
        value: playlistData.approved ? (
          playlistData.status === 'Active' ? (
            <ActiveStatus title={t('Active')} />
          ) : (
            <InactiveStatus title={t('Inactive')} />
          )
        ) : (
          <PendingStatus title={t('Pending')} />
        )
      },
      {
        label: t('Last Edited'),
        isCustomValue: true,
        value: (
          <DateTimeView
            date={playlistData.updatedAt}
            rootClassName={classes.dateTimeViewRoot}
          />
        )
      },
      {
        label: t('Added By'),
        value: `${playlistData?.createdBy?.firstName} ${playlistData?.createdBy?.lastName}`
      },
      {
        label: t('Date Added'),
        isCustomValue: true,
        value: (
          <DateTimeView
            date={playlistData.createdAt}
            rootClassName={classes.dateTimeViewRoot}
          />
        )
      }
    ]
  }, [playlistData, t, classes.dateTimeViewRoot])

  return (
    <Card
      onClick={e => e.stopPropagation()}
      icon={false}
      titleComponent={
        <TextWithTooltip
          rootClassName={classes.moreInfoCardHeaderText}
          maxWidth={310}
        >
          {item.title}
        </TextWithTooltip>
      }
      rootClassName={classes.cardRoot}
      headerClasses={[classes.moreInfoCardHeader]}
      titleComponentWrapClassName={classes.moreInfoCardHeaderWrap}
    >
      <div className={classes.container}>
        {data.map(({ label, value, isCustomValue }, index) => (
          <MoreInfoRow
            title={label}
            isTruncate={value.length > truncateLength}
            customValueType={isCustomValue}
            key={`playlist-info-card-${index}`}
            hasCorner={index < data.length - 1}
            isLoading={isFetching || isUninitialized}
          >
            {value}
          </MoreInfoRow>
        ))}
      </div>
    </Card>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(PlaylistMoreInfoCard)
)
