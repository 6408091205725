import React, { useCallback } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import UrlCard from './UrlCard'

const useStyles = makeStyles(({ type, typography }) => ({
  root: {
    width: '100%'
  },
  categoriesHeaderText: {
    ...typography.header[type],
    fontSize: '15px',
    lineHeight: '50px'
  }
}))

const IPRestrictions = ({
  t,
  values,
  deleteItem,
  updateItem,
  updateResult
}) => {
  const classes = useStyles()

  const handleClickDelete = useCallback(
    deleteIndex => {
      deleteItem({ id: deleteIndex })
    },
    [deleteItem]
  )

  const handleClickUpdate = useCallback(
    updateObj => {
      updateItem(updateObj)
    },
    [updateItem]
  )

  return (
    <div className={classes.root}>
      <header className={classes.categoriesHeader}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.categoriesHeaderText}>
              {t('Whitelisted IP Addresses')}
            </Typography>
          </Grid>
        </Grid>
      </header>
      {values.map((item, index) => (
        <UrlCard
          key={`IPWhiteListCard-${item.ip}-${index}`}
          values={item}
          index={item.id}
          onDelete={handleClickDelete}
          onUpdate={handleClickUpdate}
          updateResult={updateResult}
        />
      ))}
    </div>
  )
}

export default IPRestrictions
