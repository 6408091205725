import moment from 'moment/moment'
import update from 'immutability-helper'

import { templateStatus, templateTypes } from 'constants/template'
import { featureNames } from 'constants/featureConstants'
import { playlistTypes } from 'constants/playlist'
import { routeByName } from 'constants/index'
import { getPlaybackContentTypeByPublishTab } from './scheduleUtils'
import { getDuration, getMediaDuration } from './mediaUtils'
import {
  MEDIA_FALLBACK_TIME,
  MEDIA_MIN_TIME,
  SCHEDULE_FALLBACK_TRANSITION_ID
} from 'constants/media'

export const getTemplateFeatureNameByType = templateType => {
  switch (templateType) {
    case templateTypes.Interactive.name:
      return featureNames.InteractiveContent
    case templateTypes.Videowall.name:
      return featureNames.Videowall

    default:
      return templateTypes.Standard.name
  }
}

export const getEditPlaylistUrlByType = ({
  playlistType,
  id,
  baseUrl,
  view
}) => {
  switch (playlistType) {
    case playlistTypes.Interactive.name:
      return `${baseUrl}/${routeByName.playlist.editInteractivePlaylist(
        id,
        view
      )}`
    case playlistTypes.Smart.name:
      return `${baseUrl}/${routeByName.playlist.editSmartPlaylist(id, view)}`
    default:
      return `${baseUrl}/${routeByName.playlist.editWithId(id, view)}`
  }
}

export const getTemplateStatus = (template = {}) => {
  const { activateOn, expireOn, approved } = template

  if (template.hasOwnProperty('approved') && !approved)
    return templateStatus.notApproved

  if (expireOn && moment(expireOn).isBefore(moment())) {
    return templateStatus.expired
  }

  if (moment().isBefore(moment(activateOn))) return templateStatus.activates

  if (expireOn) {
    return templateStatus.expires
  }
}

export const prepareDropItem = (
  item,
  uid,
  type,
  transition,
  commonDuration
) => {
  const playbackContent = getPlaybackContentTypeByPublishTab(type)

  const duration =
    item.playbackContent === 'Playlist'
      ? getDuration(item, true, MEDIA_MIN_TIME)
      : getMediaDuration(item, true, MEDIA_MIN_TIME)

  return update(item, {
    uid: { $set: uid },
    duration: {
      $set:
        duration === MEDIA_MIN_TIME
          ? commonDuration || MEDIA_FALLBACK_TIME
          : duration
    },
    durationTotal: {
      $set:
        duration === MEDIA_MIN_TIME
          ? commonDuration || MEDIA_FALLBACK_TIME
          : duration
    },
    transitionId: {
      $set: transition || SCHEDULE_FALLBACK_TRANSITION_ID
    },
    playbackContent: {
      $set: playbackContent
    }
  })
}

export const getResolution = template => {
  if (
    template.width &&
    template.height &&
    ![template.width, template.height].includes('x')
  ) {
    return `${template.width} x ${template.height}`
  }
}

export const isGalleryTemplate = template => template.isGalleryTemplate

export const getTemplateCardPopupSize = isPortraitImg =>
  isPortraitImg ? { height: 443, width: 620 } : { height: 620, width: 362 }
