import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { getCRUDEndpoints } from 'utils/apiUtils'

export const clientUsersApi = createAppApi('clientUsersQuery', {
  tagTypes: [apiTags.clientUsers],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'ClientUser',
      url: '/client/user',
      builder,
      tags: [apiTags.clientUsers]
    }),
    regenerateClientUserPIN: builder.mutation({
      query: clientUserId => ({
        method: 'PUT',
        url: `/client/user/${clientUserId}/updatePinCode`
      })
    })
  })
})

export const {
  useLazyGetClientUsersQuery,
  useGetClientUserQuery,
  usePostClientUserMutation,
  usePutClientUserMutation,
  useDeleteClientUserMutation,
  useRegenerateClientUserPINMutation,
  useGetClientUsersQuery
} = clientUsersApi
